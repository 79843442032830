import { useEffect, useRef, useState } from 'react';
import { useSpring } from 'react-spring';
import { breakpoints } from '../styles/theme';

export function useClickedOutside(callBack = () => {}) {
	const ref = useRef(null);

	const handleClickOutside = event => {
		if (ref.current && !ref.current.contains(event.target)) {
			callBack();
		}
	};

	useEffect(() => {
		document.addEventListener('click', handleClickOutside, true);
		return () => {
			document.removeEventListener('click', handleClickOutside, true);
		};
	});

	return ref;
}

function getWindowDimensions() {
	const { innerWidth: width, innerHeight: height } = window;
	return {
		width,
		height,
	};
}

export function useWindowDimensions() {
	const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

	useEffect(() => {
		function handleResize() {
			setWindowDimensions(getWindowDimensions());
		}

		window.addEventListener('resize', handleResize);
		return () => window.removeEventListener('resize', handleResize);
	}, []);

	return windowDimensions;
}

function computeBreakpoint(emWidth) {
	const { width } = getWindowDimensions();
	const fontSize = window.body ? parseFloat(getComputedStyle(window.body).fontSize) : 16;
	return fontSize * parseInt(emWidth) <= width ? true : false;
}

export function useBreakPoints() {
	const [breakpoint, setBreakpoint] = useState({
		small: computeBreakpoint(breakpoints.small),
		medium: computeBreakpoint(breakpoints.medium),
		large: computeBreakpoint(breakpoints.large),
		ultra: computeBreakpoint(breakpoints.ultra),
	});

	useEffect(() => {
		function handleResize() {
			setBreakpoint({
				small: computeBreakpoint(breakpoints.small),
				medium: computeBreakpoint(breakpoints.medium),
				large: computeBreakpoint(breakpoints.large),
				ultra: computeBreakpoint(breakpoints.ultra),
			});
		}

		window.addEventListener('resize', handleResize);
		return () => window.removeEventListener('resize', handleResize);
	}, []);

	return breakpoint;
}

export function useDidMount() {
	const mountRef = useRef(false);

	useEffect(() => {
		mountRef.current = true;
	}, []);

	return () => mountRef.current;
}

export const useScrollToElement = () => {
	const [, setY] = useSpring(() => ({ y: 0 }));

	let isStopped = false;
	const onWheel = () => {
		isStopped = true;
		window.removeEventListener('wheel', onWheel);
	};

	const scrollToTarget = node => {
		const value = node ? window.scrollY + node.getBoundingClientRect().top : 0;

		window.addEventListener('wheel', onWheel);

		setY.start({
			y: value,
			reset: true,
			from: { y: window.scrollY },
			onRest: () => {
				isStopped = false;
				window.removeEventListener('wheel', onWheel);
			},
			onChange: ({ y }) => {
				if (!isStopped) {
					window.scroll(0, y);
				}
			},
		});
	};

	return { scrollToTarget };
};

export const useUnload = fn => {
	const cb = useRef(fn); // init with fn, so that type checkers won't assume that current might be undefined

	useEffect(() => {
		cb.current = fn;
	}, [fn]);

	useEffect(() => {
		const onUnload = (...args) => cb.current?.(...args);

		window.addEventListener('beforeunload', onUnload);

		return () => window.removeEventListener('beforeunload', onUnload);
	}, []);
};
