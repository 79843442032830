import React, { useContext } from 'react';
import DialogContext from './DialogContext';
import { CloseButton, StyledHeader } from './dialog.styled';
import { SubHeader } from '../../styles/util';

const DialogHeader = ({ children, closeButton }) => {
	const context = useContext(DialogContext);

	const handleClick = () => {
		context.onHide();
	};
	return (
		<StyledHeader>
			<SubHeader>{children}</SubHeader>
			{!!closeButton && (
				<CloseButton type='button' onClick={handleClick}>
					<span aria-hidden='true'>&times;</span>
				</CloseButton>
			)}
		</StyledHeader>
	);
};

export default DialogHeader;
