import { createSlice } from '@reduxjs/toolkit'

const initialLocalState = () => {
	const localstate = JSON.parse(localStorage.getItem('store2024'));
	if(localstate) return localstate.user ?? null;
	return {};
}

export const userSlice = createSlice({
	name: 'user',
	initialState: initialLocalState(),
	reducers: {
        updateUser: (state, action) => action.payload
	}
})

export const { updateUser } = userSlice.actions

export const getUser = state => state.user;

export default userSlice.reducer
