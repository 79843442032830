import { apiCall } from "./apiCall";

export const studentData = {
	update: student => {
		return new Promise((resolve, reject) => {
			// some student objects are created to be temporary, so their id is negative, if this is the case, lets just return those like we got 'em
			if (student.id < 0) return resolve({ student: student, message: 'Tentative student ' + student.firstName + ' updated!' });
			const fetch = apiCall(
				{
					student: JSON.stringify(student),
					action: 'update',
				},
				'students'
			);

			fetch.then(result => {
				if (result.error) {
					return reject(result.message);
				}
				return resolve({ student: result.object, message: result.message });
			});
		});
	},
	refresh: () => {
		return new Promise((resolve, reject) => {
			const fetch = apiCall(
				{
					action: 'refresh',
				},
				'students'
			);

			fetch.then(result => {
				if (result.error) {
					return reject(result.message);
				}
				return resolve({ students: result.array });
			});
		});
	},
};
