import React, { useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { selectStudents, selectStudent } from '../../dataStore/reducers/studentSlice';
import { getPrimary, getPromotionDayStatus, updateTitle } from '../../dataStore/reducers/appSlice';
import Button from '../button/Button';

import { AlignGrid, Main, StudentCard, CardSection, StudentInfo, EditIcon, UnqualifiedStudentCard, Highlighted } from './student.styled';
import { PurpleLink } from '../moreInfo/moreInfo.styled';
import { SmallParagraph } from '../../styles/util';

export function Students() {
	const dispatch = useDispatch();
	const location = useLocation();
	const students = useSelector(selectStudents);
	const primary = useSelector(getPrimary);
	const beforePromotionDay = useSelector(getPromotionDayStatus);

	useEffect(() => {
		if (location.pathname === '/students' && primary && primary.id) {
			dispatch(updateTitle(`Welcome ${primary.lastName} Family`));
		} else {
			dispatch(updateTitle('Get Started'));
		}
	});

	useEffect(() => {}, [students]);

	const qualified = student => {
		if (!student.id) return false;
		if (!student.additionals.length) return false;
		if (!student.emergencyContacts.length) return false;
		if (!student.medicalInfo.id) return false;
		if (!student.sharingInfo.id) return false;
		return true;
	};

	const notQualified = student => {
		if (!student.id) return true;
		if (!student.additionals.length) return true;
		if (!student.emergencyContacts.length) return true;
		if (!student.medicalInfo.id) return true;
		if (!student.sharingInfo.id) return true;
		return false;
	};

	return (
		<Main left>
			{!!students.length && (
				<AlignGrid>
					<span>Your Students</span>
					{/*  to be added later as an option */}
					{/* <Link to='/addStudent' className='text-right' onClick={() => dispatch(selectStudent(0))}>
						<PurpleLink as='button' id='addStudent'>
							Add Additional Students <AddIcon />
						</PurpleLink>
					</Link> */}
				</AlignGrid>
			)}
			<div id='studentList'>
				{students.filter(qualified).map((student, index) => (
					<StudentCard key={index}>
						<CardSection>
							<h3>
								{student.firstName + ' ' + student.lastName}
								{!!student && !!student.enrollmentBlock && student.enrollmentBlock.id > 0 && (
									<Highlighted>Ineligible for Enrollment - Expelled</Highlighted>
								)}
							</h3>
							<StudentInfo>
								<div>
									<SmallParagraph>Birthdate</SmallParagraph>
									<h3>{student.birthdate}</h3>
								</div>
								<div>
									<SmallParagraph>{beforePromotionDay ? 'Current Grade' : 'Grade in Fall'}</SmallParagraph>
									<h3>{student.shortGradeName}</h3>
								</div>
								<div>
									<SmallParagraph>School</SmallParagraph>
									<h3>{student.schoolName}</h3>
								</div>
							</StudentInfo>
						</CardSection>
						<Link to='/addStudent' className='text-right' onClick={() => dispatch(selectStudent(student.id))}>
							<PurpleLink as='button'>
								<EditIcon />
							</PurpleLink>
						</Link>
					</StudentCard>
				))}
				{students.filter(notQualified).map((student, index) => (
					<UnqualifiedStudentCard key={index}>
						<CardSection>
							<h3>
								{student.firstName + ' ' + student.lastName}
								<small> - Student Data Incomplete</small>
							</h3>
							<small>
								You will need to complete this student's information in your family account before you will be able to enroll them in
								programs.
							</small>
							<a href={'https://my.' + window.serverlocation}>
								<Button className='small'>Go to Family Account</Button>
							</a>
							<StudentInfo>
								<div>
									<SmallParagraph>Birthdate</SmallParagraph>
									<h3>{student.birthdate}</h3>
								</div>
								<div>
									<SmallParagraph>Grade</SmallParagraph>
									<h3>{student.shortGradeName}</h3>
								</div>
								<div>
									<SmallParagraph>School</SmallParagraph>
									<h3>{student.schoolName}</h3>
								</div>
							</StudentInfo>
						</CardSection>
					</UnqualifiedStudentCard>
				))}
			</div>
			{!students.length && (
				<div className='text-center'>
					<SmallParagraph>
						Looks Like You Don't Have Any Students Yet!
						<br />
						To Enroll in Programs, You Will Need to Add Them in
					</SmallParagraph>
					<a href={'https://my.' + window.serverlocation}>
						<Button>Your Family Account</Button>
					</a>
					{/* <Link to='/addStudent'>
						<Button>Add a Student</Button>
					</Link> */}
					<SmallParagraph>
						Maybe you just want to see what we have to offer?
						<br />
						If So,
					</SmallParagraph>
					<Link to='/scheduling'>
						<Button>Look at Programs</Button>
					</Link>
				</div>
			)}
			{!!students.length && (
				<div className='text-center'>
					<SmallParagraph>
						Does Your Student Information Look Correct?
						<br />
						If So,
					</SmallParagraph>
					<Link to='/scheduling'>
						<Button>Proceed To Scheduling</Button>
					</Link>
				</div>
			)}
		</Main>
	);
}
