import { createSlice } from '@reduxjs/toolkit';

export const filterSlice = createSlice({
	name: 'filter',
	initialState: {
		programs: [],
		selectedPrograms: [],
		programCount: 0,
		programTitle: 'Any Programs',
		enrollingSchools: [],
		enrollingSchoolsCount: 0,
		selectedEnrollingSchools: [],
		enrollingSchoolsTitle: 'Any Location',
		enrollingRegions: [],
		enrollingRegionsCount: 0,
		selectedEnrollingRegions: [0],
		startDateSelected: '',
		endDateSelected: '',
	},
	reducers: {
		updatePrograms: (state, action) => {
			state.programs = action.payload;
			// state.selectedPrograms = action.payload;
			state.programCount = action.payload.length;
		},
		updateSelectedPrograms: (state, action) => {
			state.selectedPrograms = action.payload.length === state.programCount ? state.programs : action.payload;
		},
		updateProgramTitle: (state, action) => {
			state.programTitle =
				action.payload.length === 0 || action.payload.length === state.programCount ? 'Any Programs' : action.payload.join(', ');
		},
		setElopOnly: state => {
			state.selectedPrograms = [1,2,3,4,5];
		},
		setNotElop: state => {
			state.selectedPrograms = [1,2,3,4];
		},
		updateEnrollingSchools: (state, action) => {
			state.enrollingSchools = action.payload;
			state.enrollingSchoolsCount = action.payload.length;
			state.selectedEnrollingSchools = action.payload.map(school => school.id);
		},
		updateSelectedEnrollingSchools: (state, action) => {
			state.selectedEnrollingSchools =
				action.payload.length === state.enrollingSchoolsCount ? state.enrollingSchools.map(school => school.id) : action.payload;
			state.enrollingSchoolsTitle = action.payload.length === state.enrollingSchoolsCount ? 'Any Location' : 'Selected Locations';
		},
		clearSchoolSelection: state => {
			state.selectedEnrollingSchools = [];
		},
		updateEnrollingRegions: (state, action) => {
			state.enrollingRegions = action.payload.regions;
			state.enrollingRegionsCount = action.payload.regions.length;
			state.selectedEnrollingRegions = action.payload.init ? [0] : action.payload.regions.map(region => region.id);
		},
		updateSelectedEnrollingRegions: (state, action) => {
			if (!action.payload.length || (!state.selectedEnrollingRegions.includes(0) && action.payload.includes(0))) {
				state.selectedEnrollingRegions = [0];
			} else if (state.selectedEnrollingRegions.includes(0) && action.payload.length > 1) {
				state.selectedEnrollingRegions = action.payload.filter(value => value > 0);
			} else {
				state.selectedEnrollingRegions = action.payload;
			}
			if (state.selectedEnrollingRegions === [0]) {
				state.enrollingSchools.map(school => school.id);
			}
			state.selectedEnrollingSchools =
				state.selectedEnrollingRegions[0] === 0
					? state.enrollingSchools.map(({ id }) => id)
					: [
							...new Set(
								[].concat(
									state.enrollingSchools
										.filter(({ regions }) =>
											regions.find(regionId => state.selectedEnrollingRegions.includes(parseInt(regionId)))
										)
										.map(({ id }) => id)
								)
							),
					  ];
		},
		updateDates: (state, action) => {
			state.startDateSelected = action.payload.startDate;
			state.endDateSelected = action.payload.endDate;
		},
	},
});

export const {
	updatePrograms,
	updateSelectedPrograms,
	updateProgramTitle,
	setElopOnly,
	setNotElop,
	updateEnrollingSchools,
	updateSelectedEnrollingSchools,
	clearSchoolSelection,
	updateEnrollingRegions,
	updateSelectedEnrollingRegions,
	updateDates,
} = filterSlice.actions;

export const getPrograms = state => state.filter.programs;
export const getSelectedPrograms = state => state.filter.selectedPrograms;
export const getProgramTitle = state => state.filter.programTitle;
export const getEnrollingSchools = state => state.filter.enrollingSchools;
export const getSelectedEnrollingSchools = state => state.filter.selectedEnrollingSchools;
export const getEnrollingSchoolsTitle = state => state.filter.enrollingSchoolsTitle;
export const getEnrollingRegions = state => state.filter.enrollingRegions;
export const getSelectedEnrollingRegions = state => state.filter.selectedEnrollingRegions;
export const getStartDateSelected = state => state.filter.startDateSelected;
export const getEndDateSelected = state => state.filter.endDateSelected;

export default filterSlice.reducer;
