import styled from 'styled-components';
import colorMod from 'color';

export const TabOffset = styled.div`
	margin-top: 0.75rem;
	margin-left: 1.5rem;
	margin-right: 1.5rem;
`;

export const Name = styled.div`
	font-size: 0.9rem;
	font-weight: ${props => (props.highlight ? 700 : 300)};
	line-height: 1.75;
	background-color: ${props => (props.highlight ? props.theme.novaColorLight : undefined)};
	margin: 0 -0.25rem;
	padding: 0 0.25rem;
`;

export const WeekGrid = styled.div`
	height: 2.75rem;
	display: grid;
	font-size: 1.125rem;
	font-family: ${({ theme }) => theme.fontCheckBox};
	grid-template-columns: repeat(auto-fit, minmax(0px, 1fr));
`;

export const Box = styled.span`
	width: 100%;
	border: 1px solid ${({ theme }) => theme.black};
	cursor: pointer;
	height: 100%;
	box-sizing: border-box;
	text-align: center;
	line-height: 2;
	background-color: ${props => (props.checked ? props.theme.preschoolColorLight : undefined)};
`;

export const BoxConflict = styled(Box)`
	cursor: not-allowed;
	color: ${({ theme }) => colorMod(theme.black).lighten(5).hex()};
	background: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' version='1.1' preserveAspectRatio='none' viewBox='0 0 100 100'><line x1='0' y1='0' x2='100' y2='100' stroke='black' vector-effect='non-scaling-stroke'/><line x1='0' y1='100' x2='100' y2='0' stroke='black' vector-effect='non-scaling-stroke'/></svg>");
	background-repeat: no-repeat;
	background-position: center center;
	background-size: 100% 100%, auto;
`;

export const BoxFull = styled(Box)`
	cursor: not-allowed;
	color: ${({ theme }) => colorMod(theme.black).lighten(5).hex()};
	background-color: ${({ theme }) => theme.errorColorLight};
`;

export const Highlighted = styled.div`
	margin: 0 -0.5rem;
	padding: 0.5rem;
	background: ${({ theme }) => theme.warningColor};
`;

export const StyledLabel = styled.label`
	color: ${({ theme }) => theme.darkGray};
	background: ${({ theme }) => theme.secondaryColorHover};
	display: inline-block;
    padding: 0.33333rem 0.5rem;
	margin-left: 1rem;
    border-radius: 0;
    font-size: 0.8rem;
    line-height: 1;
    white-space: nowrap;
    cursor: default;
`;