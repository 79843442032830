import { apiCall } from "./API/apiCall";

// this is all pretty rough, should probably return a promise and deal with it in the App.js or layout....
const authenticate = {
	isAuthenticated: false,
	checkServer(cb) {
		const fetch = apiCall(
			{},
			'/'
		);
		fetch.then(result => {
			authenticate.isAuthenticated = !result.error;
			cb(!result.error, result);
		}).catch(result => {
			authenticate.isAuthenticated = !result.error;
			cb(!result.error, result);
		});
	},
	signin(email, password, cb) {
		const fetch = apiCall(
			{
				login: true,
				email: email,
				'current-password': password,
			},
			'/'
		);
		fetch.then(result => {
			authenticate.isAuthenticated = !result.error;
			cb(!result.error, result);
		}).catch(result => {
			authenticate.isAuthenticated = !result.error;
			cb(!result.error, result);
		});
	},
	signout(cb) {
		localStorage.removeItem('store');
		const fetch = apiCall(
			{
				logout: true,
			},
			'/'
		);
		fetch.then(() => {
			authenticate.isAuthenticated = false;
			if (cb && typeof cb === 'function') cb();
		}).catch(result => {
			authenticate.isAuthenticated = false;
			if (cb && typeof cb === 'function') cb();
		});
	},
};

export default authenticate;
