import { animated } from '@react-spring/web';
import styled, { css } from 'styled-components';
import { breakpoints } from './theme';

export const centerChildren = css`
	display: block;
	padding: 1rem;
	text-align: ${props => (props.left ? 'left' : 'center')};
	> * {
		width: 100%;
	}

	@media (min-width: ${breakpoints.medium}) {
		display: grid;
		place-items: center;
		> * {
			width: calc(45% - 1.875rem);
			min-width: 33rem;
		}
	}
`;

export const Relative = styled.div`
	position: relative;
`;

export const Backdrop = styled.div`
	position: fixed;
	z-index: 1040;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	background-color: ${({ theme }) => theme.black};
	opacity: 0.5;
`;

export const SubHeader = styled.h3`
	font-size: 1rem;
	margin-bottom: 0.5rem;

	@media (min-width: ${breakpoints.medium}) {
		font-size: 1.25rem;
	}
`;

export const Emphasis = styled.span`
	color: ${({ theme }) => theme.errorColor};
	font-weight: bolder;
`;

export const Bold = styled.span`
	font-weight: bold;
`;

export const Breathe = styled.div`
	margin-top: ${props => (!props.pad ? '0.5rem' : undefined)};
	margin-bottom: ${props => (!props.pad ? '0.5rem' : undefined)};
	padding: ${props => (props.pad ? '0.25rem' : undefined)};
	line-height: 1.25;
`;

export const SpaceBefore = styled.div`
	margin-top: 0.5rem;
`;

export const ZebraStriping = styled.div`
	> div:nth-child(even) {
		background-color: ${({ theme }) => theme.white};
	}
	> div:nth-child(odd) {
		background-color: ${({ theme }) => theme.lightGray};
	}
`;

export const SmallParagraph = styled.p`
	margin: 1rem 0 1.5rem 0;
	font-size: 0.85rem;
	line-height: 1.25rem;
`;

export const Tall = styled.div`
	height: 100%;
`;

export const Grid = {
	Simple: styled.div`
		display: grid;
		grid-gap: ${props => (!props.noGap ? '1rem' : undefined)};
	`,
	Two: styled.div`
		display: grid;
		grid-template-columns: 1fr auto;
	`,
};

export const AutoGridColumn = styled.div`
	display: grid;
	grid-gap: 1em;
	place-items: center;
	grid-auto-flow: column;
`;

export const Loader = styled(animated.div)`
	display: inline-block;
	position: fixed;
	left: 50%;
	top: 50%;
	margin-top: -50px;
	margin-left: -50px;
	width: 100px;
	height: 100px;
	border: 8px solid rgba(255, 255, 255, 0.3);
	border-radius: 50%;
	border-top-color: ${({ theme }) => theme.white};
`;

export const ButtonLoader = styled(Loader)`
	position: initial;
	margin-top: 0;
	margin-left: 0;
	width: 0.9rem;
	height: 0.9rem;
	border-width: 3px;
`;
