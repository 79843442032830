import { apiCall } from "./apiCall";

const studentScheduleData = () => {
	return {
		id: 0,
		studentId: 0,
		scheduleId: 0,
		monday: false,
		tuesday: false,
		wednesday: false,
		thursday: false,
		friday: false,
		final: false,
		updatedBy: 0,
	};
};

const scheduleFilter = schedule => {
	return schedule.monday || schedule.tuesday || schedule.wednesday || schedule.thursday || schedule.friday;
};

export const studentsSchedulesData = {
	enroll: (studentSchedule, final = false) => {
		return new Promise((resolve, reject) => {
			const studentsSchedules = [];
			Object.entries(studentSchedule).forEach(student => {
				Object.entries(student[1]).forEach(schedule => {
					const scheduleData = studentScheduleData();
					scheduleData.studentId = parseInt(student[0]);
					scheduleData.scheduleId = parseInt(schedule[0]);
					scheduleData.monday = schedule[1].monday;
					scheduleData.tuesday = schedule[1].tuesday;
					scheduleData.wednesday = schedule[1].wednesday;
					scheduleData.thursday = schedule[1].thursday;
					scheduleData.friday = schedule[1].friday;
					studentsSchedules.push(scheduleData);
				});
			});
			const fetch = apiCall(
				{
					studentsSchedules: JSON.stringify(studentsSchedules.filter(scheduleFilter)),
					final: final,
					action: 'enroll',
				},
				'studentsSchedules'
			);

			fetch.then(result => {
				if (result.error) {
					return reject(result.message);
				}
				return resolve(result);
			});
		});
	},
	resetTimer: () => {
		return new Promise((resolve, reject) => {
			const fetch = apiCall(
				{	
					studentsSchedules: 'none',
					action: 'resetTimer',
				},
				'studentsSchedules'
			);

			fetch.then(result => {
				if (result.error) {
					return reject(result.message);
				}
				return resolve(result);
			});
		});
	},
	clear: () => {
		return new Promise((resolve, reject) => {
			const fetch = apiCall(
				{	
					studentsSchedules: 'none',
					action: 'clear',
				},
				'studentsSchedules'
			);

			fetch.then(result => {
				if (result.error) {
					return reject(result.message);
				}
				return resolve(result);
			});
		});
	},
	remove: (studentId, scheduleId) => {
		return new Promise((resolve, reject) => {
			const fetch = apiCall(
				{	
					studentsSchedules: 'none',
					action: 'remove',
					studentId: studentId,
					scheduleId: scheduleId,
				},
				'studentsSchedules'
			);

			fetch.then(result => {
				if (result.error) {
					return reject(result.message);
				}
				return resolve(result);
			});
		});
	},
};
