import { apiCall } from './apiCall';

export const packets = {
	createFromPayment: paymentIntent => {
		return new Promise((resolve, reject) => {
			const fetch = apiCall(
				{
					action: 'createPackets',
					paymentIntent: paymentIntent,
				},
				'packets'
			);

			fetch.then(result => {
				if (result.error) {
					return reject(result.message);
				}
				return resolve(result.array);
			});
		});
	},
	signed: (documentId, signatureId) => {
		return new Promise((resolve, reject) => {
			const fetch = apiCall(
				{
					action: 'packetSigned',
					documentId: documentId,
					signatureId: signatureId,
				},
				'packets'
			);

			fetch.then(result => {
				if (result.error) {
					return reject(result.message);
				}
				return resolve(result.array);
			});
		});
	},
};
