import { createGlobalStyle } from 'styled-components';

const GlobalStyles = createGlobalStyle`
    html, body, #root, .App {
        height: 100%;
    }

    body {
        margin: 0;
    }
    
    p {
        margin: 0;
        padding: 0;
    }
    
    h1, h2, h3, h4, h5, h6, .h {
        font-family:  ${({ theme }) => theme.h};
        font-style: normal;
        font-weight: 700;
        color: ${({ theme }) => theme.primaryColor};
        text-rendering: optimizeLegibility;
        margin: 0;
    }

    i+h1, i+h2, i+h3, i+h4, i+h5, i+h6 {
        margin-left: 1rem;
    }

    a {
        line-height: inherit;
        color: ${({ theme }) => theme.primaryColor};
        text-decoration: none;
        cursor: pointer;
        background-color: transparent;
    }

    .text-left {
        text-align: left;
    }

    .text-center {
        text-align: center;
    }

    .text-right {
        text-align: right;
    }

    /* .Toastify__progress-bar--default {
        background: ${({ theme }) => theme.primaryColor};
    } */
    :root{
        --toastify-color-progress-dark: ${({ theme }) => theme.primaryColor};
        --toastify-color-dark: ${({ theme }) => theme.black};
        --toastify-color-error: ${({ theme }) => theme.errorColor};
    }
`;

export default GlobalStyles;
