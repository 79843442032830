import styled from 'styled-components';

const Button = styled.button`
	cursor: pointer;
	padding: ${props => (props.className && props.className.includes('small') ? '0.25em 1em' : '0.5em 1.5em')};
	font-size: ${props => {
		if (props.className && props.className.includes('small')) return '1rem';
		if (props.className && props.className.includes('medium')) return '1.25rem';
		return '1.5rem';
	}};
	text-align: center;
	transition: background-color 0.25s ease-out, color 0.25s ease-out;
	font-family: ${({ theme }) => theme.btnFont};
	line-height: 1;
	border-color: ${({ theme }) => theme.btnPrimaryBorder};
	border-style: solid;
	border-width: 3px;
	border-radius: 5px;
	background-color: ${props => (props.className && props.className.includes('secondary') ? props.theme.secondaryColor : props.theme.btnPrimary)};
	color: ${({ theme }) => theme.black};

	display: ${props => (props.className && props.className.includes('expanded') ? 'block' : 'inline-block')};
	width: ${props => (props.className && props.className.includes('expanded') ? '100%' : 'initial')};

	:focus,
	:hover {
		color: ${({ theme }) => theme.lightText};
		box-shadow: 2px 2px 4px 2px ${({ theme }) => theme.btnPrimaryDropShadow};
		border-color: ${({ theme }) => theme.btnPrimaryBorderHover};
		background-color: ${props =>
			props.className && props.className.includes('secondary') ? props.theme.secondaryColorHover : props.theme.btnPrimaryHover};
	}
`;

export default Button;
