import styled from 'styled-components';

export const Main = styled.div`
	padding: 1rem;
`;

export const Dropdown = styled.div`
	text-align: left;
	background: ${({ theme }) => theme.white};
	margin: 0;
	padding: 1rem;
	width: 14.8256rem;
`;

export const MarginBottom = styled.div`
	margin-bottom: 1px;
`;

export const Pointer = styled.div`
	cursor: pointer;
`;

export const SmallGreenText = styled.div`
	font-size: 0.8rem;
	margin-bottom: 0.5rem;
	color: ${({ theme }) => theme.secondaryColor};
`;

export const Spacer = styled.div`
	margin-bottom: 1rem;
`;
