import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { studentData } from '../../libs/API';

const initialLocalState = () => {
	const localstate = JSON.parse(localStorage.getItem('store2024'));
	const students = [];
	let selected = null;
	if (localstate) {
		localstate?.students?.list?.forEach(student => {
			students.push(student);
		});
		selected = localstate?.students?.selected;
	}
	return { list: students, selected: selected };
};

export const refreshStudents = createAsyncThunk('students/refreshStudentsStatus', async () => {
	return await studentData.refresh();
});

export const studentsSlice = createSlice({
	name: 'students',
	initialState: initialLocalState(),
	reducers: {
		addStudent: (state, action) => {
			let existingStudent = state.list.find(student => student.id === action.payload.id);
			if (!existingStudent) {
				state.list.push(action.payload);
			} else {
				state.list.map(student => {
					return student.id === action.payload.id ? Object.assign(student, action.payload) : student;
				});
			}
		},
		addTempStudent: (state, action) => {
			state.list.push(action.payload);
		},
		clearStudents: state => {
			state.list = [];
			state.selected = null;
		},
		selectStudent: (state, action) => {
			state.selected = state.list.find(student => student.id === action.payload) ?? null;
		},
	},
	extraReducers: {
		[refreshStudents.fulfilled]: (state, action) => {
			state.list = action.payload.students;
		},
	},
});

export const { addStudent, addTempStudent, clearStudents, selectStudent } = studentsSlice.actions;

export const selectStudents = state => (state.students?.list || []);
export const getEligibleStudents = state => (state.students?.list || []).filter(student => student.id > 0);
export const getTentativeStudents = state => (state.students?.list || []).filter(student => student.id < 0);
export const getSelectedStudent = state => state.students?.selected || null;

export default studentsSlice.reducer;
