import { apiCall } from "./apiCall";

export const timerStatus = {
	get: () => {
		return new Promise((resolve, reject) => {
			return new Promise((resolve, reject) => {
				const fetch = apiCall(
					{	
						action: 'get',
					},
					'scheduleTime'
				);
	
				fetch.then(result => {
					if (result.error) {
						return reject(result.message);
					}
					return resolve(result);
				});
			});
		});
	},
};
