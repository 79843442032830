import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { packets } from '../../libs/API';

export const fetchPackets = createAsyncThunk('packets/fetchPacketsStatus', async paymentIntent => {
	const packetResponse = await packets.createFromPayment(paymentIntent);
	return packetResponse;
});

export const completePacket = createAsyncThunk('packets/completePacketStatus', async ({ documentId, signatureId }) => {
	const packetResponse = await packets.signed(documentId, signatureId);
	return packetResponse;
});

export const packetsSlice = createSlice({
	name: 'packets',
	initialState: {
		pending: false,
		show: false,
		completed: false,
		packets: [],
		loadedPacket: {},
	},
	reducers: {
		nextPacket: state => {
			const packets = state.packets;
			const loadedPacket = state.loadedPacket;
			if (packets.length) {
				const currentIndex = packets.map(e => e.id).indexOf(loadedPacket.id);
				if (currentIndex < packets.length - 1) {
					state.loadedPacket = packets[packets.map(e => e.id).indexOf(loadedPacket.id) + 1];
				} else {
					state.show = false;
					state.loadedPacket = null;
					state.completed = true;
				}
			} else {
				state.show = false;
				state.loadedPacket = null;
				state.completed = true;
			}
		},
	},
	extraReducers: {
		[fetchPackets.pending]: state => {
			state.show = true;
			state.pending = true;
		},
		[fetchPackets.fulfilled]: (state, action) => {
			state.packets = action.payload;
			state.loadedPacket = action.payload[0];
			state.pending = false;
		},
	},
});

export const { nextPacket } = packetsSlice.actions;

export const loadingPacket = state => state.packets.pending;
export const showPacketDialog = state => state.packets.show;
export const getLoadedPacket = state => state.packets.loadedPacket;
export const isFinished = state => state.packets.completed;

export default packetsSlice.reducer;
