/**
 * Function to create an api call promise.  Made to reduce boilerplate and streamline calls to the api.
 *
 * Along with the provided post values, it will also send the "testing" status.  Essentially, if this is
 * not starsacramento.org, then this is not a "live" situation.  Some subdomains will act significantly
 * different when this is the case.
 *
 * @param postValues the parameters that are being sent in the post to the server
 * @param endPoint the url endpoint on the api server
 * @returns as api $response, that you will need to type assert
 */
export const apiCall = (postValues, endPoint) => {
	return new Promise((resolve, reject) => {
		const params = new URLSearchParams();
		for (const [key, value] of Object.entries(postValues)) {
			params.append(`${key}`, `${value}`);
		}
		params.append('testing', window.testing.toString());
		params.append('reg2', window.reg2.toString());

		if(localStorage.getItem('auth')){
			const login = JSON.parse(atob(localStorage.getItem('auth')));
			params.append('login', 'true');
			params.append('email', login.email);
			params.append('current-password', login.password);
		}

		const requestLocation = window.apiLocation + (endPoint === '/' ? '' : 'requests/' + endPoint + '/')

		fetch(requestLocation, {
			method: 'post',
			credentials: 'include',
			headers: {
				'Content-Type': 'application/x-www-form-urlencoded',
			},
			body: params,
		})
			.then(res => res.json())
			.then(result => {
				if (result.error) {
					return reject(result);
				}

				return resolve(result);
			});
	});
};
