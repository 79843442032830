import React from 'react';

import { getSelectionFee } from '../../dataStore/reducers/scheduleSlice';
import { useSelector } from 'react-redux';

import { dollars } from '../../libs/app_lib';

import styled from 'styled-components';

const Tuition = styled.div`
	text-align: right;
	text-decoration: underline;
	line-height: 1.5;
`;
const Fee = styled.div`
	text-align: right;
	font-size: 1.5rem;
	font-family: ${({ theme }) => theme.fntSerif};
`;

const MarginRight = styled.div`
	margin-right: 1.5rem;
`;

const SelectedScheduleTotal = ({ studentId, monthly }) => {
	const feeAmount = useSelector(state => getSelectionFee(state, studentId));
	return (
		<MarginRight>
			<Tuition>
				{Boolean(monthly) && `Selected Monthly Tuition:`}
				{Boolean(!monthly) && `Session Tuition:`}
			</Tuition>
			<Fee>{dollars.format(feeAmount)}</Fee>
		</MarginRight>
	);
};

export default SelectedScheduleTotal;
