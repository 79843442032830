import React, { createContext, useEffect, useState, Suspense, lazy } from 'react';
import { Routes, Route, useNavigate, Navigate } from 'react-router-dom';
import { LoginForm } from '../login/Login';
import { Students } from '../students/Students';
import AddStudent from '../addStudent/AddStudent';
import Scheduling from '../scheduling/Scheduling';
import MenuProvider from 'react-flexible-sliding-menu';
import SchedulingFilter from '../schedulingFilter/SchedulingFilter';
import MoreInfo from '../moreInfo/MoreInfo';
import Schedule from '../schedule/Schedule';
import { Main, Content, Logo, Title, LogoutButton, Footer, FooterContent, FooterNav, LineUp, Header, Left } from './layout.styled';

import { useSelector, useDispatch } from 'react-redux';
import { getPrimary, getTitle } from '../../dataStore/reducers/appSlice';
import { getUser, updateUser } from '../../dataStore/reducers/userSlice';
import { updatePrimary, initialPayload } from '../../dataStore/reducers/appSlice';
import { updateEnrollingSchools, updateEnrollingRegions, updatePrograms, setElopOnly, setNotElop } from '../../dataStore/reducers/filtersSlice';
import { clearStudents, addStudent } from '../../dataStore/reducers/studentSlice';

import authenticate from '../../libs/authenticate';
import init from '../../libs/initialLoad';
import { logout, setTimer } from '../../dataStore/reducers/scheduleSlice';
import ScheduleBar from '../scheduleBar/ScheduleBar';
import LoadingBackDrop from '../loadingBackdrop';
import FlexBanner from 'flex-banner';
import Loading from '../finalizeSchedule/Loading';

const FinalizeSchedule = lazy(() => import('../finalizeSchedule/FinalizeSchedule'));

export const SchedulesContext = createContext();

function Layout() {
	const [schedules, setSchedules] = useState([]);
	const [programListings, setProgramListings] = useState([]);
	const [renderReady, setRenderReady] = useState(false);
	const [initialized, setInitialized] = useState(false);
	const dispatch = useDispatch();
	let navigate = useNavigate();
	const auth = useAuth();
	const user = useSelector(getUser);
	const title = useSelector(getTitle);
	const primary = useSelector(getPrimary);
	let enrollmentBlocked = false;
	if (primary && primary.id && primary.enrollmentBlock.id) {
		enrollmentBlocked = true;
	}
	useEffect(() => {
		if (!initialized) {
			auth.checkServer(valid => {
				if (!!user && !valid) {
					auth.signout();
				}
			});
			init(objects => {
				setInitialized(true);
				setSchedules(Object.values(objects.schedules).flat());
				setProgramListings(Object.values(objects.programListings).flat());
				dispatch(initialPayload(objects));
				dispatch(updateEnrollingSchools(objects.enrollingSchools));
				dispatch(updateEnrollingRegions({ regions: objects.enrollingRegions, init: true }));
				dispatch(updatePrograms(objects.programTypes.map(programType => programType.id)));
			});
		}
		// eslint-disable-next-line
	}, []);
	useEffect(() => {
		if (primary?.elop) {
			if (primary.elop.id) {
				dispatch(setElopOnly());
			} else {
				dispatch(setNotElop());
			}
			setRenderReady(true);
		}
		if (!user) {
			setRenderReady(true);
			dispatch(setNotElop());
		}
	}, [dispatch, primary, user]);

	if (renderReady) {
		return (
			<SchedulesContext.Provider value={{ schedules, programListings }}>
				{false && (
					<FlexBanner
						title='For currently enrolled families, there has been a change to how you add camps to your STAR Summer Camp Schedule. If you need to add days to an existing camp or weeks that your student is not enrolled, please'
						ctaLink='https://accounts.starsacramento.org/contact/#support'
						ctaTitle='Contact Tech Support'
						isCenter={true}
						delayToShowBanner={0}
						wrapperStyle={{ backgroundColor: '#703895', fontFamily: 'Roboto' }}
						mainStyleLink={{ color: 'white', textDecoration: 'underline' }}
					/>
				)}
				<Main>
					<div>
						<Logo to='/' />
						<div>
							<Title>
								{title}
								{user && (
									<LogoutButton
										onClick={() => {
											auth.signout(() => {
												navigate('/');
											});
										}}>
										Logout
									</LogoutButton>
								)}
							</Title>
						</div>
					</div>
					<ScheduleBar />
					<Schedule />
					{enrollmentBlocked ? (
						<LineUp>
							<div>
								<Header>Enrollment Blocked</Header>
								<Left>There is a financial hold on your account. You may not enroll until this has been resolved.</Left>
							</div>
						</LineUp>
					) : (
						<Content>
							<Routes>
								<Route path='/' element={<OpeningRoute />} />
								<Route path='/students' element={<Students />} />
								<Route path='/addStudent' element={<AddStudent />} />
								<Route
									path='/scheduling'
									element={
										<MenuProvider MenuComponent={SchedulingFilter} direction='right' width='300px'>
											<Scheduling />
										</MenuProvider>
									}
								/>
								<Route path='/moreInfo' element={<MoreInfo />} />
								<Route path='/finalize/*' element={<Suspense fallback={<Loading />}><FinalizeSchedule /></Suspense>} />
							</Routes>
						</Content>
					)}
					<LoadingBackDrop />
					<Footer>
						<FooterContent>
							<FooterNav>
								<ul>
									<li>STAR Sacramento</li>
									<li>STAR Galaxy</li>
									<li>STAR Nova</li>
									<li>STAR Preschool</li>
									<li>STAR Camps</li>
								</ul>
							</FooterNav>
						</FooterContent>
					</Footer>
				</Main>
			</SchedulesContext.Provider>
		);
	} else {
		return null;
	}
}

const OpeningRoute = () => {
	const user = useSelector(getUser);
	return user ? <Navigate to='/students' /> : <LoginForm />;
};

function useAuth() {
	const dispatch = useDispatch();
	let navigate = useNavigate();

	const checkServer = cb => {
		return authenticate.checkServer((valid, objects) => {
			if (valid) {
				dispatch(updatePrimary(objects.primary));
				dispatch(updateUser(objects.user));
				objects.students.forEach(student => {
					dispatch(addStudent(student));
				});
				dispatch(setTimer(objects.timeLeft.minutes * 60000 + objects.timeLeft.seconds * 1000));
			}
			cb(valid);
		});
	};

	const signout = cb => {
		return authenticate.signout(() => {
			dispatch(updateUser(null));
			dispatch(clearStudents());
			dispatch(updatePrimary(null));
			dispatch(logout());
			navigate('/');
			if (cb && typeof cb === 'function') cb();
		});
	};

	return {
		checkServer,
		signout,
	};
}

export default Layout;
