import React, { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { getProgramTypes, getTimeOfYear } from '../../dataStore/reducers/appSlice';
import {
	getEnrollingRegions,
	getSelectedEnrollingRegions,
	getSelectedPrograms,
	updateProgramTitle,
	updateSelectedEnrollingRegions,
	updateSelectedPrograms,
} from '../../dataStore/reducers/filtersSlice';
import { addStudent, selectStudents } from '../../dataStore/reducers/studentSlice';
import { studentData } from '../../libs/API';
import { enrollmentPeriod } from '../../libs/app_lib';
import Button from '../button/Button';
import Dialog from '../dialog/Dialog';
import CheckboxGroup from '../checkBoxGroup';
import { Grid } from '../../styles/util';
import { PrefilterContext } from '../../App';
import { getUser } from '../../dataStore/reducers/userSlice';

const FilterWizard = ({ open, handleClose }) => {
	const user = useSelector(getUser);
	const [currentStep, setCurrentStep] = useState(0);
	const students = useSelector(selectStudents);
	const { used } = useContext(PrefilterContext);

	const incrementStep = () => {
		setCurrentStep(Math.min(currentStep + 1, 2));
	};
	const decrementStep = () => {
		setCurrentStep(Math.max(currentStep - 1, 0));
	};
	useEffect(() => {
		if (!user) {
			setCurrentStep(1);
		}
	}, [user, setCurrentStep]);

	useEffect(() => {
		if (used) {
			handleClose();
		}
	}, [used, handleClose]);
	return (
		<Dialog show={!used && open} onHide={handleClose} large>
			<IncomingK currentStep={currentStep} incrementStep={incrementStep} students={students} />
			<Programs currentStep={currentStep} incrementStep={incrementStep} decrementStep={decrementStep} />
			<Regions currentStep={currentStep} decrementStep={decrementStep} handleClose={handleClose} />
		</Dialog>
	);
};

const IncomingK = ({ currentStep, incrementStep, students }) => {
	const timeOfYear = useSelector(getTimeOfYear);
	const dispatch = useDispatch();

	const title = () => {
		const kinder = 'Kindergartners or Transitional Kindergartners';
		const yearMod = enrollmentPeriod.currentYear && new Date().getMonth() < 2 ? 1 : 0;
		const year = new Date().getFullYear() + yearMod;
		if (timeOfYear === enrollmentPeriod.currentYear) return 'currently enrolled as ' + kinder;
		if (timeOfYear === enrollmentPeriod.earlyEnrollment) return 'enrolling next school year as ' + kinder;
		return 'incoming ' + kinder + ' for the ' + year + ' - ' + (year + 1) + ' school year';
	};

	const setGrade = (student, grade) => {
		let values = { ...student };
		values.gradeId = grade;
		studentData
			.update(values)
			.then(({ student, message }) => {
				dispatch(addStudent(student));
				toast(message);
			})
			.catch(errorMsg => toast.error(errorMsg));
	};

	if (currentStep === 0)
		return (
			<>
				<Dialog.Header>
					Are any of your students {title()}? <br />
				</Dialog.Header>
				<Dialog.Body>
					<small>
						This will impact enrollment in summer camps. Students that will be Kindergartners in the Fall may only enroll in Kinder camp.
						Students that are in Kindergarten now will enroll in all other camps. <b>Staff will be checking all these students.</b>
					</small>
					<br />
					<table>
						<tbody>
							{students.filter(qualified).map((student, index) => (
								<tr key={index}>
									<td>{student.firstName + ' ' + student.lastName}</td>
									<td>
										<Button className='small' onClick={() => setGrade(student, -1)}>
											TK
										</Button>
									</td>
									<td>
										<Button className='small' onClick={() => setGrade(student, 0)}>
											K
										</Button>
									</td>
								</tr>
							))}
							<tr>
								<td colSpan='3'>
									<Button onClick={() => incrementStep()} className='small expanded'>
										None/Done
									</Button>
								</td>
							</tr>
						</tbody>
					</table>
				</Dialog.Body>
				<Dialog.Footer>
					<Button onClick={() => incrementStep()} className='small'>
						Next Question
					</Button>
				</Dialog.Footer>
			</>
		);
	return null;
};
const Programs = ({ currentStep, decrementStep, incrementStep }) => {
	const dispatch = useDispatch();
	const user = useSelector(getUser);
	const selectedPrograms = useSelector(getSelectedPrograms);
	const programTypes = useSelector(getProgramTypes);
	const setPrograms = ids => {
		dispatch(updateSelectedPrograms(ids));
		dispatch(updateProgramTitle(programTypes.filter(item => ids.includes(item.id)).map(({ name }) => name)));
	};
	if (currentStep === 1)
		return (
			<>
				<Dialog.Header>What program types are you looking for?</Dialog.Header>
				<Dialog.Body>
					<CheckboxGroup name='regionsSelect' value={selectedPrograms} onChange={setPrograms}>
						{Checkbox => (
							<Grid.Simple noGap>
								{programTypes.map(
									(item, i) =>
										item.id !== 5 && (
											<label className={'program-looking-for'} key={i}>
												<Checkbox value={item.id} />
												{item.longName}
											</label>
										)
								)}
							</Grid.Simple>
						)}
					</CheckboxGroup>
				</Dialog.Body>
				<Dialog.Footer>
					{!!user && (
						<Button onClick={() => decrementStep()} className='small'>
							Previous Question
						</Button>
					)}
					<Button onClick={() => incrementStep()} className='small'>
						Next Question
					</Button>
				</Dialog.Footer>
			</>
		);
	return null;
};

const Regions = ({ currentStep, decrementStep, handleClose }) => {
	const dispatch = useDispatch();
	const enrollingRegions = useSelector(getEnrollingRegions);
	const selectedEnrollingRegions = useSelector(getSelectedEnrollingRegions);
	const setRegions = ids => dispatch(updateSelectedEnrollingRegions(ids));

	if (currentStep === 2)
		return (
			<>
				<Dialog.Header>Please choose the regions that your students are most likely to attend.</Dialog.Header>
				<Dialog.Body>
					<CheckboxGroup name='regionsSelect' value={selectedEnrollingRegions} onChange={setRegions}>
						{Checkbox => (
							<Grid.Simple noGap>
								<label>
									<Checkbox value={0} />
									Any Region
								</label>
								{enrollingRegions.map((item, i) => (
									<label key={i}>
										<Checkbox value={item.id} />
										{item.name}
									</label>
								))}
							</Grid.Simple>
						)}
					</CheckboxGroup>
				</Dialog.Body>
				<Dialog.Footer>
					<Button onClick={() => decrementStep()} className='small'>
						Previous Question
					</Button>
					<Button onClick={handleClose} className='small'>
						Apply Filter
					</Button>
				</Dialog.Footer>
			</>
		);
	return null;
};

const qualified = student => {
	if (!student.id) return false;
	if (!student.additionals.length) return false;
	if (!student.emergencyContacts.length) return false;
	if (!student.medicalInfo.id) return false;
	if (!student.sharingInfo.id) return false;
	return true;
};

export default FilterWizard;
