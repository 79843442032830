import React, { createContext, useState } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import extractDomain from 'extract-domain';
import Layout from './components/layout/Layout';
import { ThemeProvider } from 'styled-components';
import { theme } from './styles/theme';
import GlobalStyles from './GlobalStyles';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export const PrefilterContext = createContext();

function App() {
	const [used, setUsed] = useState(false);

	const parsed = extractDomain(window.location.hostname);
	var domains = new URL(window.location).hostname.split(".");

	window.reg2 = domains.includes("reg2");
	window.serverlocation = process.env.REACT_APP_NODE_ENV === 'production' ? (parsed || 'starsacramento.org') : 'jeffwebdev.net';
	window.testing = window.serverlocation !== 'starsacramento.org';
	window.apiLocation = `${process.env.REACT_APP_API_LINK}/`;

	return (
		<Router>
			<ThemeProvider theme={theme}>
				<GlobalStyles />
				<ToastContainer theme='dark' autoClose={10000} />
				<PrefilterContext.Provider value={{ used, setUsed }}>
					<Layout />
				</PrefilterContext.Provider>
			</ThemeProvider>
		</Router>
	);
}

export default App;
