import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { paymentIntent } from '../../libs/API/paymentIntent';

export const collectClientSecret = createAsyncThunk('payments/collectClientSecretStatus', async () => {
	return await paymentIntent.getIntent();
});

export const paymentsSlice = createSlice({
	name: 'payments',
	initialState: {
		secretPending: false,
		clientSecret: '',
		amount: 0,
		paymentIntentId: '',
	},
	extraReducers: {
		[collectClientSecret.pending]: state => {
			state.secretPending = true;
		},
		[collectClientSecret.fulfilled]: (state, action) => {
			console.info(action.payload);
			state.secretPending = false;
			state.clientSecret = action.payload.secret;
			state.amount = action.payload.amount;
			state.paymentIntentId = action.payload.paymentIntentId;
		},
	},
});

export const getClientSecret = state => {
	return {
		clientSecret: state.payments.clientSecret,
		secretPending: state.payments.secretPending,
		amount: state.payments.amount,
		paymentIntentId: state.payments.paymentIntentId,
	};
};

export default paymentsSlice.reducer;
