import { configureStore } from '@reduxjs/toolkit';
import appReducer from './reducers/appSlice';
import studentsReducer from './reducers/studentSlice';
import userReducer from './reducers/userSlice';
import filterReducer from './reducers/filtersSlice';
import scheduleSlice from './reducers/scheduleSlice';
import paymentsSlice from './reducers/paymentsSlice';
import packetsSlice from './reducers/packetsSlice';

export default configureStore({
	reducer: {
		app: appReducer,
		students: studentsReducer,
		user: userReducer,
		filter: filterReducer,
		schedule: scheduleSlice,
		payments: paymentsSlice,
		packets: packetsSlice,
	},
	devTools: process.env.NODE_ENV === 'development',
});
