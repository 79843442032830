import React, { useState, useEffect, useCallback } from 'react';
import { useDidMount } from '../../libs/hooks';
import Dialog from '../dialog/Dialog';
import Button from '../button/Button';

import { useSelector, useDispatch } from 'react-redux';
import {
	getShowSchedule,
	getIntendedSchedule,
	closeSchedule,
	removeSchedule,
	clearSelection,
	reloadSchedule,
	getCurrentSelection,
} from '../../dataStore/reducers/scheduleSlice';
import {
	getBilledTo,
	getConcurrentEnrollmentDiscounts,
	getOrganizationalAccountById,
	getPrimary,
	getProgramListings,
	getSchedules,
} from '../../dataStore/reducers/appSlice';
import { refreshStudents, selectStudents } from '../../dataStore/reducers/studentSlice';
import { dollars, scheduleLib, standardDate } from '../../libs/app_lib';
import { Link, useNavigate } from 'react-router-dom';
import { Bold, Breathe, SubHeader, ZebraStriping } from '../../styles/util';
import { Fees, LeftGrid, Remove, ScheduleGrid } from './schedule.styled';

const Schedule = () => {
	const didMount = useDidMount();
	const dispatch = useDispatch();
	const show = useSelector(getShowSchedule);
	const selection = useSelector(getIntendedSchedule);
	const programListings = useSelector(getProgramListings);
	const schedules = useSelector(getSchedules);
	const primary = useSelector(getPrimary);
	const students = useSelector(selectStudents);
	const currentSelection = useSelector(getCurrentSelection);
	const concurrentEnrollmentDiscounts = useSelector(getConcurrentEnrollmentDiscounts);
	const billedTo = useSelector(getBilledTo);
	const organizationalAccount = useSelector(state => {
		if (!primary || !primary.organizationalAccounts) return null;
		return getOrganizationalAccountById(state, primary.organizationalAccounts?.[0]?.organizationalAccountId);
	});

	let navigate = useNavigate();

	const [scheduleSummary, validSelection] = scheduleLib();

	const [showSchedule, setShowSchedule] = useState(false);
	const handleShowScheduleClose = useCallback(() => {
		setShowSchedule(false);
		dispatch(refreshStudents());
		dispatch(closeSchedule());
		dispatch(clearSelection());
		navigate('/scheduling');
	}, [dispatch, navigate]);

	useEffect(() => {
		if (didMount()) {
			setShowSchedule(show);
		}
	}, [didMount, show, handleShowScheduleClose]);

	const flatSchedules = Object.values(schedules).flat();
	const flatProgramListings = Object.values(programListings).flat();

	const calcFee = (
		schedule,
		monday,
		tuesday,
		wednesday,
		thursday,
		friday,
		discount,
		schedulesBilledTo,
		organizationalAccount,
		currentSelections
	) => {
		let billed = false;
		schedulesBilledTo.forEach(scheduleBilledTo => {
			if (scheduleBilledTo.scheduleId === schedule.id && organizationalAccount && scheduleBilledTo.userId === organizationalAccount.userId) {
				billed = true;
			}
		});
		if (billed) return 0;
		let useDiscount = false;
		if (discount && discount.length && currentSelections) {
			Object.entries(currentSelections).forEach(scheduleItem => {
				if (discount.some(disc => parseInt(scheduleItem[0]) === parseInt(disc.concurrentId))) {
					if (
						scheduleItem[1].monday +
							scheduleItem[1].tuesday +
							scheduleItem[1].wednesday +
							scheduleItem[1].thursday +
							scheduleItem[1].friday >
						1
					) {
						useDiscount = true;
					}
				}
			});
		}
		switch (monday + tuesday + wednesday + thursday + friday) {
			case 1:
				return useDiscount ? Number(discount[0].oneDay) : Number(schedule.oneDay) + Number(schedule.fee);
			case 2:
				return useDiscount ? Number(discount[0].twoDay) : Number(schedule.twoDay) + Number(schedule.fee);
			case 3:
				return useDiscount ? Number(discount[0].threeDay) : Number(schedule.threeDay) + Number(schedule.fee);
			case 4:
				return useDiscount ? Number(discount[0].fourDay) : Number(schedule.fourDay) + Number(schedule.fee);
			case 5:
				return useDiscount ? Number(discount[0].fiveDay) : Number(schedule.fiveDay) + Number(schedule.fee);
			default:
				return undefined;
		}
	};

	return (
		<Dialog show={showSchedule} onHide={() => handleShowScheduleClose()} large>
			<Dialog.Header>Selected Schedules</Dialog.Header>
			<Dialog.Body>
				{!!students &&
					students.map((student, index) => {
						const selectedSchedules = selection[student.id];
						if (!selectedSchedules) return '';
						return (
							<div key={index}>
								<SubHeader>{student.firstName}</SubHeader>
								{Object.entries(selectedSchedules).map((scheduleSelection, index) => {
									const selectedSchedule = validSelection(scheduleSelection[1]);
									const schedule = flatSchedules.find(schedule => parseInt(schedule.id) === parseInt(scheduleSelection[0]));
									const program = flatProgramListings.find(
										program => parseInt(program.id) === parseInt(schedule?.programListingId ?? 0)
									);
									if (!selectedSchedule || !schedule || !program) return '';
									return (
										<div key={index}>
											<ZebraStriping>
												<Bold as={Breathe} pad>
													{program.programTypeId === 3
														? program.generalTime + ' - ' + program.title
														: schedule.name + ': ' + program.group}
												</Bold>
												<ScheduleGrid>
													<LeftGrid>
														<Breathe as='span' pad>
															{standardDate.format(program.startDate) + ' - ' + standardDate.format(program.endDate)}:
														</Breathe>
														<Breathe as='span' pad>
															{scheduleSummary(selectedSchedule)}
														</Breathe>
													</LeftGrid>
													<LeftGrid>
														<Fees>
															{dollars.format(
																calcFee(
																	schedule,
																	selectedSchedule.monday,
																	selectedSchedule.tuesday,
																	selectedSchedule.wednesday,
																	selectedSchedule.thursday,
																	selectedSchedule.friday,
																	concurrentEnrollmentDiscounts[schedule.id],
																	billedTo,
																	student.studentsSubsidyEligible.id ? organizationalAccount : undefined,
																	currentSelection?.[student.id]
																)
															)}
														</Fees>
														<Remove
															onClick={() =>
																dispatch(removeSchedule({ studentId: student.id, scheduleId: schedule.id })).then(
																	dispatch(refreshStudents()).then(dispatch(reloadSchedule()))
																)
															}>
															Remove
														</Remove>
													</LeftGrid>
												</ScheduleGrid>
											</ZebraStriping>
										</div>
									);
								})}
								<hr></hr>
							</div>
						);
					})}
			</Dialog.Body>
			<Dialog.Footer>
				<Link to='/finalize'>
					<Button className='small' onClick={() => dispatch(closeSchedule())}>
						Finalize and Pay
					</Button>
				</Link>
				<Button className='small' onClick={() => handleShowScheduleClose()}>
					Continue Scheduling
				</Button>
			</Dialog.Footer>
		</Dialog>
	);
};

export default Schedule;
