import colorMod from 'color';

// private, this is the color authority for our theme
const color = {
	blue: '#289BD6',
	lightBlue: '#C9E6F5',
	purple: '#703895',
	lightPurple: '#DBCDE4',
	green: '#6CC069',
	lightGreen: '#DAEFD9',
	orange: '#F26522',
	lightOrange: '#FCD8C8',

	black: '#1A1A1A',
	white: '#FAFAFA',
	offWhite: '#F1F1F1',
	lightGray: '#E6E6E6',
	darkGray: '#505050',
};

// Fonts Setup
const fonts = {
	fontSans: '"Roboto","Helvetica Neue",Helvetica,Arial,sans-serif',
	fontSerif: '"Bree Serif", "Merriweather", Georgia, serif',
};

// this is the exported theme
export const theme = {
	background: color.white,
	contentBackground: color.offWhite,
	navBackground: color.blue,
	navBackgroundHover: color.lightblue,
	footerBackground: color.blue,
	// probably going to deprecate this language in favor of our 4 color system
	primaryColor: color.purple,
	primaryColorHover: colorMod(color.purple).darken(0.2).hex(),
	primaryColorLight: color.lightPurple,
	secondaryColor: color.green,
	secondaryColorHover: color.lightGreen,
	// maybe named after each program type?  but then what if we add more program types, more colors?
	galaxyColor: color.blue,
	galaxyColorLight: color.lightBlue,
	campsColor: color.purple,
	campsColorLight: color.lightPurple,
	preschoolColor: color.green,
	preschoolColorLight: color.lightGreen,
	novaColor: color.orange,
	novaColorLight: color.lightOrange,

	black: color.black,
	white: color.white,
	lightGray: color.lightGray,
	darkGray: color.darkGray,
	lightText: color.white,
	errorColor: '#ff6622',
	errorColorLight: '#ffeeee',
	warningColor: '#ffee22',

	// Fonts
	fontTitle: fonts.fontSerif,
	h: fonts.fontSerif,
	fontCheckBox: fonts.fontSerif,
	fntSans: fonts.fontSans,
	fntSerif: fonts.fontSerif,

	// Buttons
	btnPrimary: color.lightBlue,
	btnPrimaryHover: color.blue,
	btnPrimaryBorder: color.blue,
	btnPrimaryBorderHover: color.white,
	btnPrimaryDropShadow: color.lightBlue,
	btnFont: fonts.fontSerif,

	// Forms
	frmPrimaryBorder: color.blue,
	frmPrimaryFont: fonts.fontSerif,
};

// these are exported so that they can be used them elsewhere, but we can keep them consistent/ change them as needed.
// This may fall into disuse though as we explore other methods of responsive design
export const breakpoints = {
	small: 0,
	medium: '42em',
	large: '62em',
	ultra: '82em',
};
