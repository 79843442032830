import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import { addStudent } from '../../dataStore/reducers/studentSlice';
import { updateUser } from '../../dataStore/reducers/userSlice';
import { updatePrimary, updateTitle, getPrimary } from '../../dataStore/reducers/appSlice';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { Link } from 'react-router-dom';
import Button from '../button/Button';
import authenticate from '../../libs/authenticate';
import { reloadSchedule, setTimer } from '../../dataStore/reducers/scheduleSlice';
import { FieldError, FormError, Input, Label, LineUpIcon, MainContainer, StyledEye, StyledEyeClosed } from './login.styles';
import { SmallParagraph } from '../../styles/util';

export function LoginForm() {
	const dispatch = useDispatch();
	let navigate = useNavigate();
	const location = useLocation();
	let auth = useAuth();
	const primary = getPrimary;

	useEffect(() => {
		if (location.pathname === '/') {
			if (primary && primary.id) {
				navigate('/students');
			} else {
				dispatch(updateTitle(`Welcome to STAR Program Registration`));
			}
		}
	});

	const [passwordShown, setPasswordShown] = useState(false);
	const togglePasswordVisibility = () => {
		setPasswordShown(passwordShown ? false : true);
	};
	const [errorText, setErrorText] = useState('');

	return (
		<MainContainer className={'text-center'}>
			<SmallParagraph>
				Already Have An Account?
				<br />
				Go Ahead And Log In!
			</SmallParagraph>
			<FormError>{errorText}</FormError>
			<Formik
				initialValues={{ email: '', 'current-password': '' }}
				validationSchema={Yup.object({
					email: Yup.string().email('Must be a valid email address.').required('Please enter your email address.'),
					'current-password': Yup.string().required('Please enter your password'),
				})}
				onSubmit={(values, { setSubmitting }) => {
					setErrorText('');
					auth.signin(values.email, values['current-password'], (valid, reason) => {
						if (valid) {
							navigate('/students');
						} else {
							setErrorText(reason);
						}
					});
					setSubmitting(false);
				}}>
				{({ errors, touched }) => (
					<Form className='text-left'>
						<Label htmlFor='email'>Email Address</Label>
						<Input
							id='email'
							name='email'
							type='email'
							placeholder='Email Address'
							invalid={touched.email && errors.email ? 'true' : undefined}
							autoComplete='email'
						/>
						<FieldError name='email' component='div' />
						<Label htmlFor='current-password'>Password</Label>
						<LineUpIcon>
							<Input
								invalid={touched['current-password'] && errors['current-password'] ? 'true' : undefined}
								id='current-password'
								name='current-password'
								type={passwordShown ? 'text' : 'password'}
								placeholder='Password'
								autoComplete='current-password'
							/>
							{!passwordShown && <StyledEyeClosed onClick={togglePasswordVisibility} />}
							{!!passwordShown && <StyledEye onClick={togglePasswordVisibility} />}
							<FieldError name='current-password' component='div' />
						</LineUpIcon>
						<div className='text-right'>
							<Button type='submit'>Log In</Button>
						</div>
						<div className='text-center'>
							<em>
								<a href={'https://accounts.' + window.serverlocation + '/reset/'}>Forgot Your Password?</a>
							</em>
						</div>
					</Form>

				)}
			</Formik>
			<SmallParagraph>
				Don't Have An Account Yet?
				<br />
				That's OK! Feel Free To Just
			</SmallParagraph>
			{/* future intention "add fake students to see what enrollment would look like, with the option later to create an account" */}
			{/* <Link to='/students'>
				<Button>Get Started</Button>
			</Link> */}
			<Link to='/scheduling'>
				<Button>Look at Programs</Button>
			</Link>
			<SmallParagraph>
				Or
			</SmallParagraph>
			<a href={'https://accounts.' + window.serverlocation + '/'}>
				<Button>Create An Account</Button>
			</a>
		</MainContainer>
	);
}

function useAuth() {
	const dispatch = useDispatch();

	const signin = (email, password, cb) => {
		localStorage.setItem('auth', btoa(JSON.stringify({ email, password })));

		return authenticate.signin(email, password, (valid, objects) => {
			if (valid) {
				dispatch(updatePrimary(objects.primary));
				dispatch(updateUser(objects.user));
				objects.students.forEach(student => {
					dispatch(addStudent(student));
				});
				dispatch(reloadSchedule(objects.studentsSchedules));
				dispatch(setTimer(objects.timeLeft.minutes * 60000 + objects.timeLeft.seconds * 1000));
			}
			cb(valid, objects.reason);
		});
	};

	return {
		signin,
	};
}

export default LoginForm;
