import React, { useEffect } from 'react';
import { useSpring, animated, config } from 'react-spring';

import DialogHeader from './DialogHeader';
import DialogContext from './DialogContext';

import { Backdrop } from '../../styles/util';
import { Wrap, Default, Large, DialogContent, DialogBody, DialogFooter } from './dialog.styled';

const type = {
	default: Default,
	large: Large,
};

const Dialog = ({ children, show = false, onHide = () => {}, large = false }) => {
	const scrollOffSet = window.innerWidth - window.visualViewport.width;

	useEffect(() => {
		if (show) {
			document.body.style = `overflow: hidden; padding-right:${scrollOffSet}px;`;
		} else {
			document.body.style = '';
		}
	});
	const animateBackground = useSpring({ opacity: show ? 1 : 0 });
	const animateDialog = useSpring({
		opacity: show ? 1 : 0,
		transform: show ? 'translate3d(0%,0,0)' : 'translate3d(0,5%,0)',
		config: config.gentle,
	});

	const DialogType = type[large ? 'large' : 'default'];

	return show ? (
		<DialogContext.Provider value={{ onHide: onHide }}>
			<animated.div style={animateBackground}>
				<Backdrop />
			</animated.div>
			<Wrap>
				<animated.div style={animateDialog}>
					<DialogType>
						<DialogContent>{children}</DialogContent>
					</DialogType>
				</animated.div>
			</Wrap>
		</DialogContext.Provider>
	) : null;
};

Dialog.Header = DialogHeader;
Dialog.Body = DialogBody;
Dialog.Footer = DialogFooter;

export default Dialog;
