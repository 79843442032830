import styled from 'styled-components';
import { AutoGridColumn } from '../../styles/util';
import { breakpoints } from '../../styles/theme';

export const ScheduleGrid = styled(AutoGridColumn)`
	place-items: normal;
	@media (min-width: ${breakpoints.medium}) {
		min-width: 50vw;
		grid-template-columns: 1fr auto;
	}
`;

export const LeftGrid = styled(ScheduleGrid)`
	grid-template-columns: auto 1fr;
	min-width: initial;
`;

export const Fees = styled.span`
	font-size: 1.025rem;
	font-family: ${({ theme }) => theme.fntSerif};
`;

export const Remove = styled.span`
	color: ${({ theme }) => theme.galaxyColor};
	font-size: 0.85rem;
	text-decoration: underline;
	padding-top: 0.25rem;
	cursor: pointer;
`;
