import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import {
	getGradeGroups,
	getSchedulesByProgramId,
	getPromotionDayStatus,
	updateSelectedProgram,
	getSchools,
	getEnrollingSchools,
	getPrimary,
	getOrganizationalAccountById,
	getBilledTo,
	getRegistrationWindow,
} from '../../dataStore/reducers/appSlice';

import Button from '../button/Button';
import { Card, Bolder, StyledLabel } from './programCard.styled';
import { Grid, SpaceBefore, SubHeader } from '../../styles/util';
import { Camp, Galaxy, Nova, Prek, ProgramInfo } from '../moreInfo/moreInfo.styled';
import { standardDate } from '../../libs/app_lib';

const color = {
	color1: Galaxy,
	color2: Prek,
	color3: Camp,
	color4: Nova,
	color5: Galaxy,
};

const ProgramCard = ({ program }) => {
	const dispatch = useDispatch();
	const beforePromotionDay = useSelector(getPromotionDayStatus);
	const registrationWindow = useSelector(state => getRegistrationWindow(state, program.registrationWindowId));
	const grades = useSelector(getGradeGroups);
	const schedules = useSelector(state => getSchedulesByProgramId(state, program.id));
	const schools = useSelector(getSchools);
	const enrollingSchools = useSelector(getEnrollingSchools);
	const primary = useSelector(getPrimary);
	const organizationalAccount = useSelector(state =>
		getOrganizationalAccountById(state, primary?.organizationalAccounts?.[0]?.organizationalAccountId)
	);
	const schedulesBilledTo = useSelector(getBilledTo);
	const [isElopEligible, setIsElopEligible] = useState(false);

	useEffect(() => {
		schedules &&
			schedules.forEach(schedule => {
				schedulesBilledTo &&
					schedulesBilledTo.forEach(scheduleBilledTo => {
						if (
							scheduleBilledTo.scheduleId === schedule.id &&
							organizationalAccount &&
							scheduleBilledTo.userId === organizationalAccount.userId
						) {
							setIsElopEligible(true);
						}
					});
			});
	}, [schedules, organizationalAccount, schedulesBilledTo]);

	const thisYearNeedsPromoted = () => {
		if (new Date().getFullYear() > new Date(registrationWindow.startDate).getFullYear()) return false;
		if (!beforePromotionDay) return false;
		return true;
	};

	// may be faster to somehow calculate these in php before sending the initial payload
	const gradeMinimum = schedules
		? thisYearNeedsPromoted()
			? Math.min(...schedules.map(({ gradeMinimum }) => gradeMinimum)) - 1
			: Math.min(...schedules.map(({ gradeMinimum }) => gradeMinimum))
		: 0;
	const gradeMaximum = schedules
		? thisYearNeedsPromoted()
			? Math.max(...schedules.map(({ gradeMaximum }) => gradeMaximum)) - 1
			: Math.max(...schedules.map(({ gradeMaximum }) => gradeMaximum))
		: 0;

	// these two things are crazy, might want to rethink or calculate in php initial payload
	const tuitionMin = schedules
		? Math.min(
				...schedules.map(({ fee, oneDay, twoDay, threeDay, fourDay, fiveDay }) =>
					Math.min.apply(
						null,
						[Number(fee), Number(oneDay), Number(twoDay), Number(threeDay), Number(fourDay), Number(fiveDay)].filter(Boolean)
					)
				)
		  ) ?? 0
		: 0;
	const tuitionMax = schedules
		? Math.max(
				...schedules.map(({ fee, oneDay, twoDay, threeDay, fourDay, fiveDay }) =>
					Math.max.apply(
						null,
						[Number(fee), Number(oneDay), Number(twoDay), Number(threeDay), Number(fourDay), Number(fiveDay)].filter(Boolean)
					)
				)
		  ) ?? 0
		: 0;
	const tuitionMinMax = schedules
		? Math.min(
				...schedules.map(({ fee, oneDay, twoDay, threeDay, fourDay, fiveDay }) =>
					Math.max.apply(
						null,
						[Number(fee), Number(oneDay), Number(twoDay), Number(threeDay), Number(fourDay), Number(fiveDay)].filter(Boolean)
					)
				)
		  ) ?? 0
		: 0;

	const approximateMaxSelectable = () => {
		if (tuitionMinMax === tuitionMax) return tuitionMax;
		return tuitionMinMax + tuitionMax;
	};

	const locationName = () => {
		if (schools && enrollingSchools) {
			return enrollingSchools.filter(school => school.id === parseInt(program.schoolId))?.[0]?.name;
			// in the future this should show the actual school it is located at, for the cases like old cohort schools and host schools
			// return schools.filter(school => console.info(school.id, parseInt(enrollingAt.siteLocationId)));
		}
		return '';
	};

	const gradeAddendum = () => {
		return beforePromotionDay ? (
			<>
				{' '}
				in the <b>current</b> school year.
			</>
		) : (
			<> in the Fall.</>
		);
	};

	return (
		<Card>
			<Grid.Two>
				<SubHeader>
					{program.title} {isElopEligible && <StyledLabel>{organizationalAccount?.displayName} Funded</StyledLabel>}
				</SubHeader>
				<Link className='text-right' to='/moreInfo'>
					<Button
						className='small'
						onClick={() => {
							sessionStorage.setItem('scrollPosition', window.scrollY);
							dispatch(updateSelectedProgram(program.id));
						}}>
						More Info
					</Button>
				</Link>
			</Grid.Two>
			{/* quick description */}
			<Bolder as={color['color' + program.programTypeId]}>{program.generalTime}</Bolder>
			<ProgramInfo>{locationName()}</ProgramInfo>
			{/* grades */}
			<ProgramInfo>
				{grades[gradeMinimum][0].shortGrade !== grades[gradeMaximum][0].shortGrade &&
					grades[gradeMinimum][0].shortGrade + ' - ' + grades[gradeMaximum][0].shortGrade + ' Grade'}
				{grades[gradeMinimum][0].shortGrade === grades[gradeMaximum][0].shortGrade && grades[gradeMinimum][0].grade}
				{gradeAddendum()}
			</ProgramInfo>
			{/* dates */}
			<ProgramInfo>
				{standardDate.format(program.startDate, 'nice')} - {standardDate.format(program.endDate, 'nice')}
			</ProgramInfo>
			{/* tuition estimate */}
			<SpaceBefore>Tuition:</SpaceBefore>
			<ProgramInfo>
				{tuitionMin === Infinity && tuitionMax === -Infinity && 'No Fee For Family'}

				{!(tuitionMin === Infinity && tuitionMax === -Infinity) &&
					tuitionMin !== tuitionMax &&
					'Approximately ' +
						tuitionMin.toLocaleString('en-US', { style: 'currency', currency: 'USD' }) +
						' - ' +
						approximateMaxSelectable().toLocaleString('en-US', { style: 'currency', currency: 'USD' }) +
						' based on selected schedule'}

				{tuitionMin === tuitionMax && tuitionMin.toLocaleString('en-US', { style: 'currency', currency: 'USD' })}
			</ProgramInfo>
		</Card>
	);
};

export default ProgramCard;
