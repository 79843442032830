import { loadStripe } from '@stripe/stripe-js';
import extractDomain from 'extract-domain';

export const enrollmentPeriod = {
	currentYear: 'currentYear',
	earlyEnrollment: 'earlyEnrollment',
	lateEnrollment: 'lateEnrollment',
};

export const dollars = new Intl.NumberFormat('en-US', {
	style: 'currency',
	currency: 'USD',
});

export const standardDate = {
	format(date, type = 'nice') {
		if (type === 'nice')
			return new Date(date).toLocaleDateString('en-US', {
				timeZone: 'UTC',
				year: 'numeric',
				month: 'short',
				day: 'numeric',
			});

		return new Date(date).toLocaleDateString('en-US', {
			timeZone: 'UTC',
			year: 'numeric',
			month: '2-digit',
			day: '2-digit',
		});
	},
};

export const scheduleLib = () => {
	const summary = schedule => {
		let scheduleString = '';
		if (schedule.monday) scheduleString += 'M/';
		if (schedule.tuesday) scheduleString += 'T/';
		if (schedule.wednesday) scheduleString += 'W/';
		if (schedule.thursday) scheduleString += 'TH/';
		if (schedule.friday) scheduleString += 'F/';
		return scheduleString.slice(0, -1);
	};
	const valid = schedule => {
		if (schedule === undefined) return null;
		return !!(schedule.monday + schedule.tuesday + schedule.wednesday + schedule.thursday + schedule.friday) ? schedule : null;
	};
	return [summary, valid];
};

// export const production = window.testing; // !(!process.env.NODE_ENV || process.env.NODE_ENV === 'development');

export const stripeAdapter = {
	loadStripe: () => {
		const production = extractDomain(window.location.hostname) === 'starsacramento.org';
		return loadStripe(`${production ? process.env.REACT_APP_STRIPE_LIVE_PUBLIC : process.env.REACT_APP_STRIPE_TEST_PUBLIC}`);
	},
};

export const dateRangeOverlaps = (a_start, a_end, b_start, b_end) => {
	if (Date.parse(a_start) <= Date.parse(b_start) && Date.parse(b_start) <= Date.parse(a_end)) return true; // b starts in a
	if (Date.parse(a_start) <= Date.parse(b_end) && Date.parse(b_end) <= Date.parse(a_end)) return true; // b ends in a
	if (Date.parse(b_start) < Date.parse(a_start) && Date.parse(a_end) < Date.parse(b_end)) return true; // a in b
	return false;
};

export const timeRangeOverlaps = (a_start, a_end, b_start, b_end) => {
	if (convertTimeToNumber(a_start) < convertTimeToNumber(b_start) && convertTimeToNumber(b_start) < convertTimeToNumber(a_end)) return true; // b starts in a
	if (convertTimeToNumber(a_start) < convertTimeToNumber(b_end) && convertTimeToNumber(b_end) < convertTimeToNumber(a_end)) return true; // b ends in a
	if (convertTimeToNumber(b_start) < convertTimeToNumber(a_start) && convertTimeToNumber(a_end) < convertTimeToNumber(b_end)) return true; // a in b
	return false;
};

export const convertTimeToNumber = time => {
	const hours = Number(time.split(':')[0]);
	const minutes = Number(time.split(':')[1]) / 60;
	const seconds = Number(time.split(':')[2]) / 360;
	return hours + minutes + seconds;
};

export const isPrimaryComplete = primary => {
	if (!primary) return false;
	if (!primary.driversLicense || primary.driversLicense === '') return false;
	if (!primary.address || primary.address === '') return false;
	if (!primary.employer || primary.employer === '') return false;
	return true;
};
