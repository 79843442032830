import React, { useContext, useState } from 'react';
import { MenuContext } from 'react-flexible-sliding-menu';
import CheckboxGroup from '../checkBoxGroup';
import Button from '../button/Button';

// day selector toggle buttons, for later
// import ToggleButton from '../toggleButton/ToggleButton';

import { useSelector, useDispatch } from 'react-redux';
import { getProgramTypes } from '../../dataStore/reducers/appSlice';
import {
	getSelectedPrograms,
	getProgramTitle,
	updateSelectedPrograms,
	updateProgramTitle,
	getEnrollingSchools,
	getSelectedEnrollingSchools,
	getEnrollingSchoolsTitle,
	updateSelectedEnrollingSchools,
	clearSchoolSelection,
	getStartDateSelected,
	getEndDateSelected,
	updateDates,
} from '../../dataStore/reducers/filtersSlice';

import { Dropdown, Main, MarginBottom, Pointer, SmallGreenText, Spacer } from './schedulingFilter.styled';
import { GreenLink } from '../moreInfo/moreInfo.styled';
import { Grid, Relative } from '../../styles/util';

const SchedulingFilter = () => {
	const { closeMenu } = useContext(MenuContext);
	const dispatch = useDispatch();

	// day/time selector open state, for later
	// const [timeOpen, setTimeOpen] = useState(false);

	// program type selector
	const [programOpen, setProgramOpen] = useState(false);
	const programTypes = useSelector(getProgramTypes);
	const programs = useSelector(getSelectedPrograms);
	const programTitle = useSelector(getProgramTitle);
	const setProgram = ids => {
		dispatch(updateSelectedPrograms(ids));
		dispatch(updateProgramTitle(programTypes.filter(item => ids.includes(item.id)).map(({ name }) => name)));
	};

	// school selector
	const [schoolOpen, setSchoolOpen] = useState(false);
	const schools = useSelector(getEnrollingSchools);
	const selectedEnrollingSchools = useSelector(getSelectedEnrollingSchools);
	const enrollingSchoolsTitle = useSelector(getEnrollingSchoolsTitle);
	const setSchools = ids => dispatch(updateSelectedEnrollingSchools(ids));

	// date selector
	const [datesOpen, setDatesOpen] = useState(false);
	const startDateSelection = useSelector(getStartDateSelected);
	const endDateSelection = useSelector(getEndDateSelected);
	const setDateSelection = () =>
		dispatch(
			updateDates({
				startDate: document.querySelector('#startDate').value,
				endDate: document.querySelector('#endDate').value,
			})
		);

	return (
		<Main>
			<div onClick={closeMenu} className='text-right'>
				<Pointer as='svg' xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'>
					<path d='M24 20.188l-8.315-8.209 8.2-8.282-3.697-3.697-8.212 8.318-8.31-8.203-3.666 3.666 8.321 8.24-8.206 8.313 3.666 3.666 8.237-8.318 8.285 8.203z' />
				</Pointer>
			</div>
			<div>
				<h5>Filter Options</h5>
				<div>
					<span>
						<MarginBottom as={Button} onClick={() => setProgramOpen(programOpen => !programOpen)} className='expanded'>
							<div>{programTitle}</div>
						</MarginBottom>
						{!!programOpen && (
							<Dropdown>
								<Relative>
									<h5>Show These Programs</h5>
									<CheckboxGroup name='programTypesSelect' value={programs} onChange={setProgram}>
										{Checkbox => (
											<Grid.Simple noGap>
												{programTypes.map(
													(item, i) =>
														item.id !== 5 && (
															<label key={i}>
																<Checkbox value={item.id} />
																{item.name}
															</label>
														)
												)}
											</Grid.Simple>
										)}
									</CheckboxGroup>
									<Button onClick={() => setProgramOpen(false)} className='small'>
										Close
									</Button>
								</Relative>
							</Dropdown>
						)}
					</span>
					<span>
						<MarginBottom as={Button} onClick={() => setSchoolOpen(schoolOpen => !schoolOpen)} className='expanded'>
							<div>{enrollingSchoolsTitle}</div>
						</MarginBottom>
						{!!schoolOpen && (
							<Dropdown>
								<Relative>
									<h5>Show These Schools</h5>
									<GreenLink onClick={() => dispatch(clearSchoolSelection())}>Clear Selection</GreenLink>
									<CheckboxGroup name='schoolsSelect' value={selectedEnrollingSchools} onChange={setSchools}>
										{Checkbox => (
											<Grid.Simple noGap>
												{schools.map((item, i) => (
													<label key={i}>
														<Checkbox value={item.id} />
														{item.name}
													</label>
												))}
											</Grid.Simple>
										)}
									</CheckboxGroup>
									<Button onClick={() => setSchoolOpen(false)} className='small'>
										Close
									</Button>
								</Relative>
							</Dropdown>
						)}
					</span>
					<span>
						<MarginBottom as={Button} onClick={() => setDatesOpen(datesOpen => !datesOpen)} className='expanded'>
							<div>Any Date</div>
						</MarginBottom>
						{!!datesOpen && (
							<Dropdown>
								<Relative>
									<SmallGreenText>
										To show year long programs, start date must be blank or set to early in September and end date must be blank
										or set to late June.
									</SmallGreenText>
									<h5>Starts after</h5>
									<input id='startDate' type='date' value={startDateSelection} onChange={setDateSelection}></input>
									<h5>Ends Before</h5>
									<input id='endDate' type='date' value={endDateSelection} onChange={setDateSelection}></input>
									<Spacer></Spacer>
									<Button onClick={() => setDatesOpen(false)} className='small'>
										Close
									</Button>
								</Relative>
							</Dropdown>
						)}
					</span>
					{/* day and time stuff for later */}
					{/* <span>
                        <Button onClick={() => setTimeOpen(timeOpen => !timeOpen)} className="expanded">
                            Any Day or Time
                        </Button>
                        {!!timeOpen
                        && <Dropdown>
                                <Relative>
                                    <h5>Show Courses on These Days</h5>
                                    <div className={classes.toggleGroup}>
                                        <ToggleButton>M</ToggleButton>
                                        <ToggleButton>T</ToggleButton>
                                        <ToggleButton>W</ToggleButton>
                                        <ToggleButton>TH</ToggleButton>
                                        <ToggleButton>F</ToggleButton>
                                    </div>
                                    <h5>Show Courses During These Times</h5>
                                    <Button onClick={() => setTimeOpen(false)} className="expanded">Close</Button>
                                </Relative>
                            </Dropdown>}
                    </span> */}
					{/* <DropdownButton>Time</DropdownButton>
                    <DropdownButton>Grade</DropdownButton>
                    <DropdownButton>School</DropdownButton> */}
				</div>
			</div>
		</Main>
	);
};

export default SchedulingFilter;
