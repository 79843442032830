import React, { useState, useEffect, useCallback, useContext } from 'react';

import { addSelection, getCurrentStudentSelection } from '../../dataStore/reducers/scheduleSlice';
import { useDispatch, useSelector } from 'react-redux';

import Bubble from '../bubble/Bubble';
import { Box, BoxConflict, BoxFull, Highlighted, Name, StyledLabel, TabOffset, WeekGrid } from './weekSelector.styled';
import {
	getConcurrentEnrollmentDiscountsByScheduleId,
	getProgramListingById,
	getSchedulesByStudentsSchedules,
	getPrimary,
	getOrganizationalAccountById,
	getBilledTo,
	getPromotionDayStatus,
	getRegistrationWindow,
} from '../../dataStore/reducers/appSlice';
import { dateRangeOverlaps, timeRangeOverlaps } from '../../libs/app_lib';
import { SchedulesContext } from '../layout/Layout';

const WeekSelector = ({ schedule, student, validate, stopValidate = () => {} }) => {
	const dispatch = useDispatch();
	const programListing = useSelector(state => getProgramListingById(state, schedule.programListingId));
	const beforePromotionDay = useSelector(getPromotionDayStatus);
	const registrationWindow = useSelector(state => getRegistrationWindow(state, programListing.registrationWindowId));
	const studentSchedules = useSelector(state => getSchedulesByStudentsSchedules(state, student.studentsSchedules));
	const currentSelections = useSelector(state => getCurrentStudentSelection(state, student.id));
	const discount = useSelector(state => getConcurrentEnrollmentDiscountsByScheduleId(state, schedule.id));
	const primary = useSelector(getPrimary);

	const organizationalAccountId = student.studentsSubsidyEligible.id ? primary.organizationalAccounts?.[0]?.organizationalAccountId : 0;

	const organizationalAccount = useSelector(state => getOrganizationalAccountById(state, organizationalAccountId));
	const schedulesBilledTo = useSelector(getBilledTo);
	const [isElopEligible, setIsElopEligible] = useState(false);

	const { programListings, schedules } = useContext(SchedulesContext);

	const [monday, setMonday] = useState(false);
	const [tuesday, setTuesday] = useState(false);
	const [wednesday, setWednesday] = useState(false);
	const [thursday, setThursday] = useState(false);
	const [friday, setFriday] = useState(false);

	const [mondayConflict, setMondayConflict] = useState(false);
	const [tuesdayConflict, setTuesdayConflict] = useState(false);
	const [wednesdayConflict, setWednesdayConflict] = useState(false);
	const [thursdayConflict, setThursdayConflict] = useState(false);
	const [fridayConflict, setFridayConflict] = useState(false);

	const [useDiscount, setUseDiscount] = useState(false);

	const thisYearNeedsPromoted = () => {
		if (new Date().getFullYear() > new Date(registrationWindow.startDate).getFullYear()) return false;
		if (!beforePromotionDay) return false;
		return true;
	};

	// may be faster to somehow calculate these in php before sending the initial payload
	const gradeMinimum = schedule ? (thisYearNeedsPromoted() ? schedule.gradeMinimum - 1 : schedule.gradeMinimum) : 0;
	const gradeMaximum = schedules ? (thisYearNeedsPromoted() ? schedule.gradeMaximum - 1 : schedule.gradeMaximum) : 0;

	const calcFee = useCallback(
		(monday, tuesday, wednesday, thursday, friday, staticFee) => {
			if (schedule.hidePricing) return 0;
			if (isElopEligible) return 0;
			switch (monday + tuesday + wednesday + thursday + friday) {
				case 1:
					return useDiscount ? Number(discount[0].oneDay) : Number(schedule.oneDay) + Number(staticFee);
				case 2:
					return useDiscount ? Number(discount[0].twoDay) : Number(schedule.twoDay) + Number(staticFee);
				case 3:
					return useDiscount ? Number(discount[0].threeDay) : Number(schedule.threeDay) + Number(staticFee);
				case 4:
					return useDiscount ? Number(discount[0].fourDay) : Number(schedule.fourDay) + Number(staticFee);
				case 5:
					return useDiscount ? Number(discount[0].fiveDay) : Number(schedule.fiveDay) + Number(staticFee);
				default:
					return undefined;
			}
		},
		[useDiscount, discount, schedule, isElopEligible]
	);

	const twoDayCheck = useCallback(() => {
		return schedule.twoDayMinimum && monday + tuesday + wednesday + thursday + friday === 1 && validate ? true : false;
	}, [schedule.twoDayMinimum, monday, tuesday, wednesday, thursday, friday, validate]);

	useEffect(() => {
		if (discount && discount.length) {
			setUseDiscount(false);
			currentSelections.forEach(scheduleItem => {
				if (discount.some(disc => parseInt(scheduleItem[0]) === parseInt(disc.concurrentId))) {
					if (
						scheduleItem[1].monday +
							scheduleItem[1].tuesday +
							scheduleItem[1].wednesday +
							scheduleItem[1].thursday +
							scheduleItem[1].friday >
						1
					) {
						setUseDiscount(true);
					}
				}
			});
		}
	}, [discount, currentSelections, student.id, schedule.id]);

	useEffect(() => {
		dispatch(
			addSelection({
				studentId: student.id,
				scheduleId: schedule.id,
				twoDayMinimum: schedule.twoDayMinimum,
				monday: monday,
				tuesday: tuesday,
				wednesday: wednesday,
				thursday: thursday,
				friday: friday,
				fee: calcFee(monday, tuesday, wednesday, thursday, friday, schedule.fee),
			})
		);
	}, [monday, tuesday, wednesday, thursday, friday, student.id, schedule.id, schedule.twoDayMinimum, schedule.fee, calcFee, dispatch, schedule]);

	useEffect(() => {
		if (schedule.concurrent === 2) {
			setMondayConflict(false);
			setTuesdayConflict(false);
			setWednesdayConflict(false);
			setThursdayConflict(false);
			setFridayConflict(false);

			// need to check against current selection and actual enrollment...  could get dicey
			currentSelections.forEach(scheduleItem => {
				if (
					scheduleItem[1].monday +
						scheduleItem[1].tuesday +
						scheduleItem[1].wednesday +
						scheduleItem[1].thursday +
						scheduleItem[1].friday ===
					0
				)
					return;
				const scheduleInfo = schedules.find(schedule => schedule.id === parseInt(scheduleItem[0]));
				const program = programListings.find(programListing => programListing.id === scheduleInfo.programListingId);
				const scheduleTest = schedules.find(schedule => schedule.id === parseInt(scheduleItem[0]));
				console.log(scheduleInfo);
				if (scheduleInfo?.concurrent === 1) return;

				if (
					program &&
					parseInt(scheduleItem[0]) !== schedule.id &&
					dateRangeOverlaps(program.startDate, program.endDate, programListing.startDate, programListing.endDate) &&
					timeRangeOverlaps(scheduleInfo.startTime, scheduleInfo.endTime, schedule.startTime, schedule.endTime)
				) {
					console.info('I am ' + schedule.name, 'checking with:', scheduleTest.name);
					if (scheduleItem[1].monday) setMondayConflict(true);
					if (scheduleItem[1].tuesday) setTuesdayConflict(true);
					if (scheduleItem[1].wednesday) setWednesdayConflict(true);
					if (scheduleItem[1].thursday) setThursdayConflict(true);
					if (scheduleItem[1].friday) setFridayConflict(true);
				}
			});
			student.studentsSchedules.forEach(studentSchedule => {
				const scheduleInfo = studentSchedules.find(schedule => schedule.id === studentSchedule.scheduleId);
				if (scheduleInfo?.concurrent === 1) return;
				if (
					scheduleInfo?.programListing?.startDate &&
					dateRangeOverlaps(
						scheduleInfo.programListing.startDate,
						scheduleInfo.programListing.endDate,
						programListing.startDate,
						programListing.endDate
					) &&
					timeRangeOverlaps(scheduleInfo.startTime, scheduleInfo.endTime, schedule.startTime, schedule.endTime)
				) {
					if (studentSchedule.monday) setMondayConflict(true);
					if (studentSchedule.tuesday) setTuesdayConflict(true);
					if (studentSchedule.wednesday) setWednesdayConflict(true);
					if (studentSchedule.thursday) setThursdayConflict(true);
					if (studentSchedule.friday) setFridayConflict(true);
				}
			});
		}
	}, [
		schedules,
		currentSelections,
		mondayConflict,
		programListing.endDate,
		programListing.startDate,
		schedule,
		student.studentsSchedules,
		studentSchedules,
		programListings,
	]);

	useEffect(() => {
		schedulesBilledTo &&
			schedulesBilledTo.forEach(scheduleBilledTo => {
				if (
					scheduleBilledTo.scheduleId === schedule.id &&
					organizationalAccount &&
					scheduleBilledTo.userId === organizationalAccount.userId
				) {
					setIsElopEligible(true);
				}
			});
	});

	const setAll = () => {
		if (!!schedule.monday) setMonday(monday => !monday);
		if (!!schedule.tuesday) setTuesday(tuesday => !tuesday);
		if (!!schedule.wednesday) setWednesday(wednesday => !wednesday);
		if (!!schedule.thursday) setThursday(thursday => !thursday);
		if (!!schedule.friday) setFriday(friday => !friday);
	};

	const alreadyEnrolled = () => {
		return student.studentsSchedules.some(studentSchedule => {
			return studentSchedule.scheduleId === schedule.id;
		});
	};

	const concurrencyIssue = () => {
		if (schedule.concurrent === 1) return false;
		if (schedule.concurrent === 3) {
			return studentSchedules.some(studentSchedule => {
				if (!studentSchedule.programListing) return false;
				if (studentSchedule.concurrent === 1) return false;
				return dateRangeOverlaps(
					studentSchedule.programListing.startDate,
					studentSchedule.programListing.endDate,
					programListing.startDate,
					programListing.endDate
				);
			});
		}
	};

	if (student.gradeId < gradeMinimum || student.gradeId > gradeMaximum) {
		return null;
	} else if (alreadyEnrolled()) {
		return (
			<Highlighted>
				{student.firstName} is already enrolled in or has the {schedule.dayAndTime} schedule selected for this program.
			</Highlighted>
		);
	} else if (concurrencyIssue()) {
		return <Highlighted>{student.firstName} is enrolled in or has another program schedule selected during this time.</Highlighted>;
	} else {
		return (
			<TabOffset fee={calcFee(monday, tuesday, wednesday, thursday, friday, schedule.fee) || 0}>
				<Name highlight={schedule.hidePricing}>
					{isElopEligible && <StyledLabel>This schedule is {organizationalAccount.displayName} Funded</StyledLabel>}{' '}
					{schedule.name + ': ' + schedule.dayAndTime + (schedule.twoDayMinimum ? ' - Two Day Minimum' : '')}
				</Name>
				<Bubble show={twoDayCheck()} onHide={stopValidate} error>
					This schedule requires a two day minimum selection.
				</Bubble>
				<WeekGrid>
					{!!schedule.monday && (
						<RenderDay
							scheduleConflict={mondayConflict}
							dayFull={schedule.mondayEnrollment >= schedule.classSizeMax}
							onClick={() => (schedule.fullTime ? setAll() : setMonday(monday => !monday))}
							checked={monday}>
							Mon
						</RenderDay>
					)}
					{!!schedule.tuesday && (
						<RenderDay
							scheduleConflict={tuesdayConflict}
							dayFull={schedule.tuesdayEnrollment >= schedule.classSizeMax}
							onClick={() => (schedule.fullTime ? setAll() : setTuesday(tuesday => !tuesday))}
							checked={tuesday}>
							Tues
						</RenderDay>
					)}
					{!!schedule.wednesday && (
						<RenderDay
							scheduleConflict={wednesdayConflict}
							dayFull={schedule.wednesdayEnrollment >= schedule.classSizeMax}
							onClick={() => (schedule.fullTime ? setAll() : setWednesday(wednesday => !wednesday))}
							checked={wednesday}>
							Wed
						</RenderDay>
					)}
					{!!schedule.thursday && (
						<RenderDay
							scheduleConflict={thursdayConflict}
							dayFull={schedule.thursdayEnrollment >= schedule.classSizeMax}
							onClick={() => (schedule.fullTime ? setAll() : setThursday(thursday => !thursday))}
							checked={thursday}>
							Thurs
						</RenderDay>
					)}
					{!!schedule.friday && (
						<RenderDay
							scheduleConflict={fridayConflict}
							dayFull={schedule.fridayEnrollment >= schedule.classSizeMax}
							onClick={() => (schedule.fullTime ? setAll() : setFriday(friday => !friday))}
							checked={friday}>
							Fri
						</RenderDay>
					)}
				</WeekGrid>
			</TabOffset>
		);
	}
};

function RenderDay({ scheduleConflict = false, dayFull = false, onClick, checked, children }) {
	if (dayFull) {
		return <BoxFull>{children} (Full)</BoxFull>;
	} else if (scheduleConflict) {
		return <BoxConflict>{children}</BoxConflict>;
	} else {
		return (
			<Box onClick={onClick} checked={checked}>
				{children}
			</Box>
		);
	}
}

export default WeekSelector;
