import styled from 'styled-components';
import { centerChildren } from '../../styles/util';

export const Main = styled.div`
	${centerChildren}
`;

export const Spacing = styled.div`
	> div {
		margin-bottom: 0.25rem;
	}
	> div:last-child {
		margin-bottom: 0;
	}
`;
export const Div = styled.div``;
const HeaderBase = styled.h1`
	color: ${({ theme }) => theme.black};
	padding: 0.5rem;
	font-size: 1.5rem;
	text-align: left;
`;

export const Galaxy = styled(HeaderBase)`
	background-color: ${({ theme }) => theme.galaxyColorLight};
`;

export const Prek = styled(HeaderBase)`
	background-color: ${({ theme }) => theme.preschoolColorLight};
`;

export const Camp = styled(HeaderBase)`
	background-color: ${({ theme }) => theme.campsColorLight};
`;

export const Nova = styled(HeaderBase)`
	background-color: ${({ theme }) => theme.novaColorLight};
`;

// this no worky, doesn't like the color format
export const Elop = styled(HeaderBase)`
	background-color: linear-gradient(
		${({ theme }) => theme.preschoolColorLight},
		${({ theme }) => theme.campsColorLight},
		${({ theme }) => theme.novaColorLight},
		${({ theme }) => theme.galaxyColorLight}
	);
`;
