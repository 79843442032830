import React, { useEffect } from 'react';
import { useSpring, animated } from 'react-spring';

import { useDispatch, useSelector } from 'react-redux';
import { getAddToSchedulePendingState, showBackgroundLoader } from '../../dataStore/reducers/scheduleSlice';
import { Backdrop, Loader } from '../../styles/util';

const LoadingBackDrop = () => {
	const show = useSelector(getAddToSchedulePendingState);
	const dispatch = useDispatch();

	const animateBackground = useSpring({ opacity: show ? 1 : 0 });
	const animateLoader = useSpring({
		loop: true,
		from: { rotateZ: 0 },
		to: { rotateZ: 360 },
		config: { mass: 3, tension: 190, friction: 20 },
	});

	useEffect(() => {
		setTimeout(show => {
			if (show) console.info('this should correct itself now');
		}, 3000);
		setTimeout(() => {
			dispatch(showBackgroundLoader(false));
		}, 10000);
	});

	return show ? (
		<animated.div style={animateBackground}>
			<Backdrop>
				<Loader style={animateLoader} />
			</Backdrop>
		</animated.div>
	) : null;
};

export const QuickBackdrop = () => {
	const animateBackground = useSpring({ opacity: 1 });
	const animateLoader = useSpring({
		loop: true,
		from: { rotateZ: 0 },
		to: { rotateZ: 360 },
		config: { mass: 3, tension: 190, friction: 20 },
	});

	return (
		<animated.div style={animateBackground}>
			<Backdrop>
				<Loader style={animateLoader} />
			</Backdrop>
		</animated.div>
	);
};

export default LoadingBackDrop;
