import React, { useEffect, useRef, useState } from 'react';
import { useSpring, animated, config } from 'react-spring';
import { useMatch } from 'react-router-dom';

import Button from '../button/Button';

import { useSelector, useDispatch } from 'react-redux';
import {
	getShowScheduleBar,
	showSchedule,
	getIntendedSchedule,
	clearSchedule,
	getTimerValue,
	setDelta,
	syncTimer,
} from '../../dataStore/reducers/scheduleSlice';
import Countdown, { zeroPad } from 'react-countdown';
import { useUnload } from '../../libs/hooks';
import { Main, Timer, Title } from './scheduleBar.styled';
import { Grid } from '../../styles/util';
import { theme } from '../../styles/theme';
import { refreshStudents } from '../../dataStore/reducers/studentSlice';

const ScheduleBar = () => {
	const countdownRef = useRef(null);

	const show = useSelector(getShowScheduleBar);
	const timerReset = useSelector(getTimerValue);
	const selection = useSelector(getIntendedSchedule);
	const dispatch = useDispatch();
	const match = useMatch('/finalize/');

	const [currentTime, setCurrentTime] = useState(timerReset || Date.now() + 100000);

	const animateDialog = useSpring({
		opacity: show ? 1 : 0,
		transform: show ? 'translate3d(0%,0,0)' : 'translate3d(0,5%,0)',
		config: config.gentle,
	});

	const n = useRef(0);
	const loop = () => {
		let result = false;
		if (n.current < 2) result = true;
		n.current++;
		if (n.current > 2) n.current = 0;
		return result;
	};
	const [highlightStyles, highlightApi] = useSpring(() => ({
		loop: loop,
		from: { rotateX: 30, backgroundColor: theme.preschoolColorLight },
		to: { rotateX: 0, backgroundColor: theme.preschoolColor },
		config: config.stiff,
	}));

	useEffect(() => {
		setCurrentTime(Date.now() + timerReset);
		if (show) {
			setTimeout(() => {
				highlightApi.start({ loop: loop });
				if (!!countdownRef.current && countdownRef.current.state) {
					countdownRef.current.start();
				} else {
					console.error(countdownRef.current ?? 'no countdownRef');
				}
			}, 100);
		}
	}, [show, setCurrentTime, highlightApi, countdownRef, timerReset]);

	useEffect(() => {
		if (Object.keys(selection).length === 0) {
			dispatch(setDelta(0));
		}
	}, [selection, dispatch]);

	useUnload(e => dispatch(syncTimer()));

	const renderer = ({ minutes, seconds }) => {
		return (
			<Timer>
				{zeroPad(minutes)} min {zeroPad(seconds)} sec
			</Timer>
		);
	};

	const clear = () => {
		dispatch(clearSchedule()).then(dispatch(refreshStudents()));
	};

	const tick = newDelta => {
		dispatch(setDelta(newDelta.total));
	};

	return !!show && (!match || match.url !== '/finalize') ? (
		<animated.div style={animateDialog}>
			<Main style={highlightStyles}>
				<Grid.Simple>
					<Title className='text-left'>
						You have <Countdown ref={countdownRef} date={currentTime} renderer={renderer} onComplete={clear} onTick={tick} /> make charges
						to, or finalize this schedule. Your reserved schedule WILL BE LOST once this session expires.
					</Title>

					<Button className='small' onClick={() => dispatch(showSchedule())}>
						View Your Schedule
					</Button>
				</Grid.Simple>
			</Main>
		</animated.div>
	) : null;
};

export default ScheduleBar;
