import { ErrorMessage, Field, Form } from 'formik';
import styled from 'styled-components';
import { centerChildren } from '../../styles/util';

export const StyledForm = styled(Form)`
	${centerChildren}
`;

export const StyledLabel = styled.label`
	color: ${({ theme }) => theme.black};
	display: block;
	margin: 0;
	text-align: left;
	font-size: 0.875rem;
	font-weight: 400;
	line-height: 1.8;
`;

export const Input = styled(Field)`
	transition: box-shadow 0.5s, border-color 0.25s ease-in-out;
	transition: border-color 0.25s ease-in-out, -webkit-box-shadow 0.5s;
	color: ${({ theme }) => theme.black};
	margin: 0 0 1rem;
	display: block;
	padding: 0.5rem;
	font-size: 1rem;
	box-shadow: inset 0 1px 2px rgb(10 10 10 / 10%);
	box-sizing: border-box;
	transition: box-shadow 0.5s, border-color 0.25s ease-in-out, -webkit-box-shadow 0.5s;
	font-family: ${({ theme }) => theme.frmPrimaryFont};
	font-weight: 400;
	line-height: 1.5;
	border-color: ${({ theme }) => theme.frmPrimaryBorder};
	border-style: solid;
	border-width: 3px;
	border-radius: 5px;
	background-color: ${({ theme }) => theme.background};
	appearance: none;
`;

export const StyledErrorMessage = styled(ErrorMessage)`
	top: -1rem;
	color: ${({ theme }) => theme.errorColor};
	position: relative;
	font-size: small;
	margin-bottom: -0.95rem;
`;

export const Small = styled.p`
	margin: 1rem 0;
	font-size: 0.85rem;
	line-height: 1.25rem;
`;
