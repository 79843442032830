import styled from 'styled-components';
import { breakpoints } from '../../styles/theme';

export const Wrap = styled.div`
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	outline: 0;
	z-index: 1040;
	overflow: auto;
	position: fixed;
`;

export const Default = styled.div`
	width: auto;
	margin: 0.5rem;
	position: relative;
	pointer-events: none;

	@media (min-width: ${breakpoints.medium}) {
		margin: 1.75rem auto;
		max-width: 500px;
	}
`;

export const Large = styled(Default)`
	@media (min-width: ${breakpoints.medium}) {
		margin: 1rem auto;
		max-width: max(70%, 655px);
	}
`;

export const DialogContent = styled.div`
	width: 100%;
	display: flex;
	outline: 0;
	position: relative;
	border-color: ${({ theme }) => theme.black};
	border-style: solid;
	border-width: 1px;
	border-radius: 5px;
	flex-direction: column;
	pointer-events: auto;
	background-clip: padding-box;
	background-color: ${({ theme }) => theme.white};
`;

export const StyledHeader = styled.div`
	display: flex;
	padding: 1rem;
	align-items: center;
	border-bottom: 1px solid #dee2e6;
	flex-direction: column;
	justify-content: space-between;
`;

export const CloseButton = styled.button`
	color: #1a1a1a;
	float: right;
	border: 0;
	cursor: pointer;
	margin: -1rem -1rem -1rem auto;
	opacity: 0.5;
	padding: 1rem;
	font-size: 1.5rem;
	font-weight: 700;
	line-height: 1;
	text-shadow: 0 1px 0 #fff;
	background-color: transparent;

	:hover {
		opacity: 0.75;
	}

	:focus:not(:focus-visible) {
		outline: 0;
	}
`;

export const DialogBody = styled.div`
	flex: 1 1 auto;
	display: flex;
	padding: 1rem;
	position: relative;
	align-items: center;
	flex-direction: column;

	p:not(:first-of-type) {
		margin-top: 1em;
	}
`;

export const DialogFooter = styled.div`
	gap: 1rem;
	display: flex;
	padding: 0.75rem;
	flex-wrap: wrap;
	border-top: 1px solid #dee2e6;
	align-items: center;
	justify-content: flex-end;
`;
