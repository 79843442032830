import { FaUserEdit, FaUserPlus } from 'react-icons/fa';
import styled from 'styled-components';
import { centerChildren, Grid } from '../../styles/util';

export const Main = styled.div`
	${centerChildren}
`;

export const AlignGrid = styled(Grid.Two)`
	align-items: baseline;
	margin: 0;
`;

export const AddIcon = styled(FaUserPlus)`
	font-size: 2rem;
`;

export const EditIcon = styled(FaUserEdit)`
	font-size: 2rem;
	margin: 0.5rem;
	text-align: right;
`;

export const StudentCard = styled.div`
	color: ${({ theme }) => theme.black};
	border: 1px solid ${({ theme }) => theme.lightGray};
	display: grid;
	overflow: hidden;
	background: ${({ theme }) => theme.lightText};
	box-shadow: none;
	margin-top: 0.15rem;
	border-radius: 0;
	justify-content: space-between;
	grid-template-columns: 1fr 4rem;
`;

export const UnqualifiedStudentCard = styled(StudentCard)`
	background: ${({ theme }) => theme.errorColorLight};
`;

export const CardSection = styled.div`
	padding: 1rem;
	h3,
	h5 {
		color: ${({ theme }) => theme.black};
		font-weight: 400;
	}
`;

export const StudentInfo = styled.div`
	display: grid;
	padding-top: 0.5rem;
	grid-template-columns: 2fr 1fr 1fr;
	p {
		margin: 0;
	}
`;

export const Highlighted = styled.small`
	background-color: ${({ theme }) => theme.errorColor};
	margin: 1rem;
	padding: 0 0.5rem;
	font-size: 70%;
`;
