import { ErrorMessage, Field } from 'formik';
import { GoEye, GoEyeClosed } from 'react-icons/go';
import styled, { css } from 'styled-components';
import { centerChildren } from '../../styles/util';

export const MainContainer = styled.div`
	${centerChildren}
`;

export const FormError = styled.div`
	color: ${({ theme }) => theme.errorColor};
	height: 2.5rem;
`;

export const Label = styled.label`
	color: ${({ theme }) => theme.black};
	margin: 0;
	display: block;
	font-size: 0.875rem;
	font-weight: 400;
	line-height: 1.8;
`;

export const FieldError = styled(ErrorMessage)`
	top: -1rem;
	color: ${({ theme }) => theme.errorColor};
	position: relative;
	font-size: small;
	margin-bottom: -0.95rem;
`;

const IconBase = css`
	right: 3.5em;
	cursor: pointer;
	z-index: 1;
	position: relative;
	margin-top: 0.95rem;
	padding-left: 1.25rem;
`;

export const StyledEye = styled(GoEye)`
	${IconBase}
`;

export const StyledEyeClosed = styled(GoEyeClosed)`
	${IconBase}
`;

export const Input = styled(Field)`
	transition: box-shadow 0.5s, border-color 0.25s ease-in-out;
	transition: border-color 0.25s ease-in-out, -webkit-box-shadow 0.5s;
	color: ${({ theme }) => theme.black};
	width: 100%;
	margin: 0 0 1rem;
	display: block;
	padding: 0.5rem;
	font-size: 1rem;
	box-shadow: inset 0 1px 2px rgb(10 10 10 / 10%);
	box-sizing: border-box;
	transition: box-shadow 0.5s, border-color 0.25s ease-in-out, -webkit-box-shadow 0.5s;
	font-family: ${({ theme }) => theme.frmPrimaryFont};
	font-weight: 400;
	line-height: 1.5;
	border-color: ${({ theme }) => theme.frmPrimaryBorder};
	border-style: solid;
	border-width: 3px;
	border-radius: 5px;
	background-color: ${props => (props.invalid ? props.theme.errorColorLight : props.theme.background)};
`;

export const LineUpIcon = styled.div`
	display: grid;
	grid-template-columns: 1fr 0;
	${Input} {
		padding-right: 2.5em;
	}
`;
