import { apiCall } from './apiCall';

export const paymentIntent = {
	getIntent: () => {
		return new Promise((resolve, reject) => {
			const fetch = apiCall(
				{
					action: 'getIntent',
				},
				'paymentIntent'
			);

			fetch.then(result => {
				if (result.error) {
					return reject(result.message);
				}
				return resolve({ secret: result.object.secret, amount: result.object.amount, paymentIntentId: result.object.paymentIntentId });
			});
		});
	},
};
