import { createSlice } from '@reduxjs/toolkit';
import { convertTimeToNumber, enrollmentPeriod } from '../../libs/app_lib';

const initialLocalState = () => {
	const localstate = JSON.parse(localStorage.getItem('store2024'));
	if (localstate) return localstate.app.selectedProgram ?? null;
	return {};
};

export const appSlice = createSlice({
	name: 'app',
	initialState: {
		notInitialized: true,
		title: 'Welcome to STAR Program Registration',
		primary: {},
		registrationWindows: [],
		registrationWindowsBilledTo: [],
		organizationalAccounts: [],
		scheduleBilledTo: [],
		programListings: [],
		schedules: [],
		scheduleSelectiveVisibility: [],
		concurrentEnrollmentDiscounts: [],
		extendedSchedules: [],
		programTypes: [],
		selectedProgram: initialLocalState(),
		schools: [],
		enrollingSchools: [],
		enrollingRegions: [],
		grades: [],
		gradeGroups: [],
		promotionDay: '',
	},
	reducers: {
		updateTitle: (state, action) => {
			state.title = action.payload;
		},
		updatePrimary: (state, action) => {
			state.primary = action.payload;
		},
		initialPayload: (state, action) => {
			state.notInitialized = false;
			state.registrationWindows = action.payload.registrationWindows;
			state.registrationWindowsBilledTo = action.payload.registrationWindowsBilledTo;
			state.organizationalAccounts = action.payload.organizationalAccounts;
			state.scheduleBilledTo = action.payload.scheduleBilledTo;
			state.programListings = action.payload.programListings;
			state.schedules = action.payload.schedules;
			state.scheduleSelectiveVisibility = action.payload.scheduleSelectiveVisibility;
			state.concurrentEnrollmentDiscounts = action.payload.concurrentEnrollmentDiscounts;
			state.extendedSchedules = action.payload.extendedSchedules;
			state.programTypes = action.payload.programTypes;
			state.schools = action.payload.schools;
			state.enrollingSchools = action.payload.enrollingSchools;
			state.enrollingRegions = action.payload.enrollingRegions;
			state.grades = action.payload.grades;
			state.gradeGroups = action.payload.gradeGroups;
			state.promotionDay = action.payload.globals.promotionDay;
		},
		updateSelectedProgram: (state, action) => {
			const programs = Object.values(state.programListings).flat();
			state.selectedProgram = programs.find(program => program.id === action.payload);
		},
	},
});

export const { updateTitle, updatePrimary, initialPayload, updateSelectedProgram } = appSlice.actions;

export const notInitialized = state => state.app.notInitialized;
export const getTitle = state => state.app.title;
export const getPrimary = state => state.app.primary;
export const getRegistrationWindows = state => state.app.registrationWindows;
export const getRegistrationWindow = (state, id) => {
	return state.app.registrationWindows.find(window => window.id === id);
};
export const getRegistrationWindowsBilledTo = state => state.app.registrationWindowsBilledTo;
export const getOrganizationalAccounts = state => state.app.organizationalAccounts;
export const getOrganizationalAccountById = (state, id) => {
	return state.app.organizationalAccounts.find(account => account.id === id);
};
export const getProgramListings = state => state.app.programListings;
export const getProgramListingById = (state, id) => {
	const programs = Object.values(state.app.programListings).flat();
	return programs.find(program => program.id === id);
};
export const getSchedules = state => state.app.schedules;
// not sure why this doesn't work, it's just flattening out the values.
// export const getFlatSchedules = state => Object.values(state.app.schedules).flat();
export const getScheduleById = (state, id) => {
	const schedules = Object.values(state.schedules).flat();
	return schedules.find(schedule => schedule.id === id);
};
export const getScheduleVisibilityByScheduleId = (state, id) => {
	return state.scheduleSelectiveVisibility?.[id]?.[0];
};
export const getSchedulesByStudentsSchedules = (state, studentsSchedules) => {
	return studentsSchedules.map(studentSchedule => {
		const schedules = Object.values(state.app.schedules).find(schedule => schedule[0].id === studentSchedule.scheduleId);
		const programs = Object.values(state.app.programListings).flat();
		return schedules?.length
			? Object.assign({}, schedules[0], { programListing: programs.find(program => program.id === schedules[0].programListingId) })
			: [];
	});
};
export const getSchedulesByProgramId = (state, id) =>
	!!state.app.schedules[id] &&
	state.app.schedules[id]
		.slice()
		.sort(
			(a, b) =>
				convertTimeToNumber(a.startTime) - convertTimeToNumber(b.startTime) || convertTimeToNumber(a.endTime) - convertTimeToNumber(b.endTime)
		);
export const getConcurrentEnrollmentDiscounts = state => state.app.concurrentEnrollmentDiscounts;
export const getConcurrentEnrollmentDiscountsByScheduleId = (state, id) => state.app.concurrentEnrollmentDiscounts[id];
export const getExtendedSchedules = state => state.app.extendedSchedules;
export const getExtendedSchedulesByProgramId = (state, id) => state.app.extendedSchedules[id];
export const getProgramTypes = state => state.app.programTypes;
export const getSchools = state => state.app.schools;
export const getEnrollingSchools = state => state.app.enrollingSchools;
export const getRegions = state => state.app.enrollingRegions;
export const getGrades = state => state.app.grades;
export const getGradeGroups = state => state.app.gradeGroups;
export const getSelectedProgram = state => state.app.selectedProgram;
export const getPromotionDayStatus = state => Date.now() < Date.parse(state.app.promotionDay);
export const getTimeOfYear = state => {
	const today = new Date();
	const june = new Date(new Date().getFullYear(), 5);
	const promotionDay = new Date(state.app.promotionDay);
	const august = new Date(new Date().getFullYear(), 7);

	if (today > june) {
		if (today > promotionDay) {
			if (today > august) return enrollmentPeriod.currentYear;
			return enrollmentPeriod.lateEnrollment;
		}
		return enrollmentPeriod.earlyEnrollment;
	}
	return enrollmentPeriod.currentYear;
};
export const getBilledToBySchedule = (state, scheduleId) => {
	return state.app.scheduleBilledTo.find(scheduleBilled => scheduleBilled.scheduleId === scheduleId);
};
export const getBilledTo = state => state.app.scheduleBilledTo;

export default appSlice.reducer;
