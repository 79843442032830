import React, { useLayoutEffect, useState } from 'react';
import { useClickedOutside, useBreakPoints } from '../../libs/hooks';

import { Norm, Error, Pointer } from './bubble.styled';

const type = {
	default: Norm,
	error: Error,
};

const Bubble = ({ children, show = false, onHide = () => {}, error = false }) => {
	const breakpoints = useBreakPoints();
	const ref = useClickedOutside(onHide);
	const [position, setPosition] = useState({});
	const [pointerPosition, setPointerPosition] = useState({});

	const BubbleType = type[error ? 'error' : 'default'];

	useLayoutEffect(() => {
		if (ref.current === null) return;
		const self = ref.current;
		const parent = ref.current.parentElement;
		const nextSib = ref.current.nextSibling;

		function updatePosition() {
			if (breakpoints.medium) {
				setPosition({ left: parent.offsetLeft + parent.offsetWidth + 16 });
				setPointerPosition({ left: '-0.5rem', top: (self.offsetHeight - 16) / 2 });
			} else {
				setPosition({ top: nextSib.offsetTop - self.offsetHeight - 16 });
				setPointerPosition({ bottom: '-0.5rem', left: self.offsetWidth / 2 });
			}
		}
		window.addEventListener('resize', updatePosition);
		updatePosition();
		return () => window.removeEventListener('resize', updatePosition);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [show, breakpoints]);

	return (
		!!show && (
			<BubbleType ref={ref} style={position} className='error'>
				<Pointer style={pointerPosition}></Pointer>
				{children}
			</BubbleType>
		)
	);
};

export default Bubble;
