import styled from 'styled-components';
import logo from '../../img/logo192.png';
import { Link } from 'react-router-dom';
import Button from '../button/Button';
import { breakpoints } from '../../styles/theme';

export const Main = styled.div`
	display: grid;
	height: 100vh;
	grid-template-rows: auto 1fr auto;
	margin: 0;
	font-family: ${({ theme }) => theme.fntSans};
`;

export const Header = styled.h3`
	font-size: 1rem;
	margin-bottom: 0.5rem;

	@media (min-width: ${breakpoints.medium}) {
		font-size: 1.25rem;
	}
`;

export const Content = styled.div`
	background: ${({ theme }) => theme.contentBackground};
`;

export const Logo = styled(Link)`
	display: block;
	height: 10vh;
	background: #fefefe url(${logo});
	background-size: contain;
	background-repeat: no-repeat;
	background-position: left center;
	font-weight: bold;
	text-decoration: none;
	text-transform: uppercase;
	margin-left: 1rem;
	:hover {
		color: ${({ theme }) => theme.primaryColor};
	}
`;

export const Title = styled.div`
	display: table-cell;
	height: 50px;
	width: 100vw;
	background-color: ${({ theme }) => theme.navBackground};
	color: ${({ theme }) => theme.lightText};
	text-align: center;
	vertical-align: middle;
	font-size: 1.125rem;
	text-decoration: none;
	font-family: ${({ theme }) => theme.fontTitle};
`;

export const LogoutButton = styled(Button)`
	&& {
		float: right;
		margin-right: 1rem;
		font-size: 1rem;
		padding: 0.25em 1em;
	}
`;

export const LineUp = styled.div`
	display: block;
	padding: 1rem;
	text-align: ${props => (props.left ? 'left' : 'center')};
	> * {
		width: 100%;
	}

	@media (min-width: ${breakpoints.medium}) {
		display: grid;
		place-items: flex-start center;
		> * {
			width: calc(45% - 1.875rem);
			min-width: 33rem;
		}
	}
`;

export const Left = styled.p`
	text-align: left;
`;

export const Footer = styled.div`
	background-color: ${({ theme }) => theme.footerBackground};
`;

export const FooterContent = styled.div`
	display: grid;
	background: url(${logo});
	background-size: contain;
	background-repeat: no-repeat;
	background-position: left center;
	margin-left: 1rem;
`;

export const FooterNav = styled.div`
	ul {
		list-style-type: none;
		text-align: right;
		line-height: 2;
		margin-right: 1rem;
		color: ${({ theme }) => theme.white};
		font-weight: 700;
	}
`;
