import styled from 'styled-components';
import { breakpoints } from '../../styles/theme';

const Base = styled.div`
	position: absolute;
	left: 0;
	right: 0;
	background-clip: padding-box;
	padding: 0.6rem;
	margin-right: auto;
	margin-left: auto;
	border-radius: 5px;
	outline: 0;
	max-width: 250;

	@media (min-width: ${breakpoints.medium}) {
		margin-left: initial;
		margin-right: 1rem;
	}
`;

export const Norm = styled(Base)`
	color: ${({ theme }) => theme.white};
	background-color: ${({ theme }) => theme.primaryColor};
	@media (min-width: ${breakpoints.medium}) {
		margin-left: initial;
		margin-right: 1rem;
	}
`;

export const Pointer = styled.div`
	position: absolute;
	box-sizing: border-box;
	transform: rotate(45deg);
	height: 16px;
	width: 16px;
	background-color: ${({ theme }) => theme.primaryColor};
`;

export const Error = styled(Base)`
	color: ${({ theme }) => theme.errorColorLight};
	background-color: ${({ theme }) => theme.errorColor};
	${Pointer} {
		background: ${({ theme }) => theme.errorColor};
	}
`;
