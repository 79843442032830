// import React, { useContext, useEffect } from 'react';
import React, { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getProgramTypes, getProgramListings, getPrimary } from '../../dataStore/reducers/appSlice';
import { getSelectedPrograms, getSelectedEnrollingSchools, getStartDateSelected, getEndDateSelected } from '../../dataStore/reducers/filtersSlice';
import { MenuContext } from 'react-flexible-sliding-menu';

import ProgramCard from '../programCard/ProgramCard';

import { getIntendedSchedule, getShowScheduleBar, setTimer } from '../../dataStore/reducers/scheduleSlice';
import { timerStatus } from '../../libs/API/timerStatus';
import { Main, PurpleLink } from '../moreInfo/moreInfo.styled';
import { Breathe, Grid } from '../../styles/util';
import { Camp, Div, Galaxy, Nova, Prek, Spacing } from './scheduling.styled';
import FilterWizard from '../filterWizard/';
import { PrefilterContext } from '../../App';
import { isPrimaryComplete } from '../../libs/app_lib';
import { getUser } from '../../dataStore/reducers/userSlice';
import styled from 'styled-components';

const color = {
	color1: Galaxy,
	color2: Prek,
	color3: Camp,
	color4: Nova,
	color5: Galaxy,
};

const Scheduling = () => {
	const [badPrimary, setBadPrimary] = useState(false);
	const programListings = useSelector(getProgramListings);
	const programTypes = useSelector(getProgramTypes);
	const programFilter = useSelector(getSelectedPrograms);
	const startDate = useSelector(getStartDateSelected);
	const endDate = useSelector(getEndDateSelected);
	const schedule = useSelector(getIntendedSchedule);
	const show = useSelector(getShowScheduleBar);
	const primary = useSelector(getPrimary);
	const user = useSelector(getUser);
	const { setUsed } = useContext(PrefilterContext);

	const dispatch = useDispatch();

	useEffect(() => {
		if (Object.keys(schedule).length && !show) {
			timerStatus.get().then(({ timeLeft }) => {
				dispatch(setTimer(timeLeft.minutes * 60000 + timeLeft.seconds * 1000));
			});
		}
	});

	useEffect(() => {
		const scrollPosition = sessionStorage.getItem('scrollPosition');
		if (scrollPosition) {
			window.scrollTo(0, parseInt(scrollPosition, 10));
			sessionStorage.removeItem('scrollPosition');
		}
	});

	// eslint-disable-next-line react-hooks/exhaustive-deps
	useEffect(() => {
		// if (primary?.elop?.id) {
		// 	handleWizardClose();
		// 	setIsElop(true);
		// }
		if (user && !isPrimaryComplete(primary)) {
			setBadPrimary(true);
		} else {
			setBadPrimary(false);
		}
	});

	const [openWizard, setOpenWizard] = useState(true);
	const handleWizardClose = () => {
		setUsed(true);
		setOpenWizard(false);
	};
	// const handleWizardShow = () => setOpenWizard(true);

	const schoolFilter = useSelector(getSelectedEnrollingSchools);
	const programsFilter = item =>
		schoolFilter.includes(parseInt(item.schoolId)) &&
		(!startDate || new Date(item.startDate) >= new Date(startDate)) &&
		(!endDate || new Date(item.endDate) <= new Date(endDate));

	const { openMenu } = useContext(MenuContext);

	if (badPrimary)
		return (
			<Main>
				<Card>
					<h3 className='text-center'>Incomplete Primary Information</h3>
					<p>
						It appears that the Primary Guardian on your account is incomplete. You must go to{' '}
						<Underlined href='https://my.starsacramento.org'>your account</Underlined> and complete all primary information to enroll your
						students.
					</p>
				</Card>
			</Main>
		);

	return (
		<Main>
			<>
				<FilterWizard open={openWizard} handleClose={handleWizardClose}></FilterWizard>
				<Grid.Two as={Breathe}>
					<div>Available Programs</div>
					<PurpleLink onClick={openMenu}>Filter Programs</PurpleLink>
				</Grid.Two>
			</>
			{!!programTypes &&
				programTypes
					.filter(item => programFilter.includes(item.id))
					.map(
						(programType, index) =>
							!!programListings[programType.name] &&
							!!programListings[programType.name].filter(programsFilter).length && (
								<div key={index}>
									<Div as={color['color' + programType.id]}>{programType.name}</Div>
									<Spacing>
										{!!programListings[programType.name] &&
											programListings[programType.name].filter(programsFilter).map((programListing, index) => (
												<div key={index}>
													<ProgramCard program={programListing}></ProgramCard>
												</div>
											))}
									</Spacing>
								</div>
							)
					)}
		</Main>
	);
};

export default Scheduling;

const Card = styled.div`
	padding: 1rem;
	color: ${({ theme }) => theme.black};
	border: 1px solid ${({ theme }) => theme.lightGray};
	overflow: hidden;
	background: ${({ theme }) => theme.lightText};
	box-shadow: none;
	margin-top: 0.15rem;
	border-radius: 0;
`;

const Underlined = styled.a`
	text-decoration: underline;
`;
