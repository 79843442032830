import styled from 'styled-components';
import { centerChildren } from '../../styles/util';

export const Main = styled.div`
	${centerChildren}
	text-align: initial;
`;

export const ProgramInfo = styled.div`
	font-size: 0.9rem;
	font-weight: 300;
	line-height: 1.75;
	margin: 0 -0.5rem;
	padding: 0 0.5rem;
`;

export const Galaxy = styled(ProgramInfo)`
	background-color: ${({ theme }) => theme.galaxyColorLight};
`;

export const Prek = styled(ProgramInfo)`
	background-color: ${({ theme }) => theme.preschoolColorLight};
`;

export const Camp = styled(ProgramInfo)`
	background-color: ${({ theme }) => theme.campsColorLight};
`;

export const Nova = styled(ProgramInfo)`
	background-color: ${({ theme }) => theme.novaColorLight};
`;

export const ProgramDescription = styled.div`
	margin-top: 0.5rem;
	line-height: 1.25;
	margin-bottom: 0.5rem;
	white-space: break-spaces;
`;

export const BaseLink = styled.span`
	border: none;
	cursor: ${props => (props.textOnly ? 'initial' : 'pointer')};
	line-height: 1.75;
	text-decoration: underline;
	background-color: transparent;
`;

export const BlueLink = styled(BaseLink)`
	color: ${({ theme }) => theme.galaxyColor};
`;

export const PurpleLink = styled(BaseLink)`
	color: ${({ theme }) => theme.primaryColor};
`;

export const OrangeLink = styled(BaseLink)`
	color: ${({ theme }) => theme.novaColor};
`;

export const GreenLink = styled(BaseLink)`
	color: ${({ theme }) => theme.preschoolColor};
`;

export const Highlighted = styled.small`
	background-color: ${({ theme }) => theme.errorColor};
	margin: 1rem;
	padding: 0 0.5rem;
	font-size: 70%;
`;
