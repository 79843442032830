import React, { useState, useRef, useEffect } from 'react';
import { Link } from 'react-router-dom';

import Button from '../button/Button';
import WeekSelector from '../weekSelector/WeekSelector';
import SelectedScheduleTotal from '../selectedScheduleTotal/SelectedScheduleTotal';
import Dialog from '../dialog/Dialog';

import {
	getSelectedProgram,
	getGradeGroups,
	getSchedulesByProgramId,
	getSchools,
	getEnrollingSchools,
	getPromotionDayStatus,
	initialPayload,
	getRegistrationWindow,
} from '../../dataStore/reducers/appSlice';
import { getUser } from '../../dataStore/reducers/userSlice';
import { getEligibleStudents, getTentativeStudents, refreshStudents } from '../../dataStore/reducers/studentSlice';
import { clearSelection, addToSchedule, addToStudentSchedule } from '../../dataStore/reducers/scheduleSlice';
import { useSelector, useDispatch } from 'react-redux';

import { BlueLink, Highlighted, Main, OrangeLink, ProgramDescription, ProgramInfo, PurpleLink } from './moreInfo.styled';
import { AutoGridColumn, Bold, Breathe, Grid, SubHeader, Tall } from '../../styles/util';
import init from '../../libs/initialLoad';
import { standardDate } from '../../libs/app_lib';

const MoreInfo = () => {
	const ref = useRef(null);
	const user = useSelector(getUser);
	const program = useSelector(getSelectedProgram);
	const grades = useSelector(getGradeGroups);
	const schedules = useSelector(state => getSchedulesByProgramId(state, program.id));
	const schools = useSelector(getSchools);
	const enrollingSchools = useSelector(getEnrollingSchools);
	const beforePromotionDay = useSelector(getPromotionDayStatus);
	const registrationWindow = useSelector(state => getRegistrationWindow(state, program.registrationWindowId));
	const dispatch = useDispatch();

	const eligibleStudents = useSelector(getEligibleStudents);
	const tentativeStudents = useSelector(getTentativeStudents);

	const totalStudents = eligibleStudents.length + tentativeStudents.length;

	const [showRegRequirementsInfo, setShowRegRequirementsInfo] = useState(false);
	const handleRegRequirementsInfoClose = () => setShowRegRequirementsInfo(false);
	const handleRegRequirementsInfoShow = () => setShowRegRequirementsInfo(true);

	const [showEligibilityInfo, setShowEligibilityInfo] = useState(false);
	const handleShowEligibilityInfoClose = () => setShowEligibilityInfo(false);
	const handleShowEligibilityInfoShow = () => setShowEligibilityInfo(true);

	const [showTentativeInfo, setShowTentativeInfo] = useState(false);
	const handleShowTentativeInfoClose = () => setShowTentativeInfo(false);
	const handleShowTentativeInfoShow = () => setShowTentativeInfo(true);

	const [validateSchedules, setValidateSchedules] = useState(false);
	const startScheduleValidation = () => setValidateSchedules(true);
	const clearScheduleValidation = () => setValidateSchedules(false);

	const thisYearNeedsPromoted = () => {
		if (new Date().getFullYear() > new Date(registrationWindow.startDate).getFullYear()) return false;
		if (!beforePromotionDay) return false;
		return true;
	};

	// may be faster to somehow calculate these in php before sending the initial payload
	const gradeMinimum =
		schedules &&
		(thisYearNeedsPromoted()
			? Math.min(...schedules.map(({ gradeMinimum }) => gradeMinimum)) - 1
			: Math.min(...schedules.map(({ gradeMinimum }) => gradeMinimum)));
	const gradeMaximum =
		schedules &&
		(thisYearNeedsPromoted()
			? Math.max(...schedules.map(({ gradeMaximum }) => gradeMaximum)) - 1
			: Math.max(...schedules.map(({ gradeMaximum }) => gradeMaximum)));

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	const locationName = () => {
		if (schools && enrollingSchools) {
			return enrollingSchools.filter(school => school.id === parseInt(program.schoolId))?.[0]?.name;
			// in the future this should show the actual school it is located at, for the cases like old cohort schools and host schools
			// return schools.filter(school => console.info(school.id, parseInt(enrollingAt.siteLocationId)));
		}
		return '';
	};

	const gradeStatement = () => {
		if (gradeMaximum === undefined || gradeMinimum === undefined) return '';
		if (!!Object.values(grades).length && grades[gradeMinimum][0].shortGrade !== grades[gradeMaximum][0].shortGrade)
			return (
				grades[gradeMinimum][0].shortGrade +
				' - ' +
				grades[gradeMaximum][0].shortGrade +
				' grade' +
				(thisYearNeedsPromoted() ? ' in the current school year.' : ' in the Fall.')
			);
		if (!!Object.values(grades).length && grades[gradeMinimum][0].shortGrade === grades[gradeMaximum][0].shortGrade)
			return grades[gradeMinimum][0].grade;
	};

	const qualified = student => {
		if (!schools.length) return false;
		if (typeof gradeMaximum === 'undefined') return false;
		if (typeof gradeMinimum === 'undefined') return false;
		if (!student.id) return false;
		if (!student.additionals.length) return false;
		if (!student.emergencyContacts.length) return false;
		if (!student.medicalInfo.id) return false;

		if (!student.sharingInfo.id) return false;
		// console.log(!(schools.filter(school => school.id === parseInt(student.schoolId))[0].siteLocationId === parseInt(program.schoolId)), !(schools.filter(school => school.id === parseInt(student.schoolId))[0].id === parseInt(program.schoolId)));
		if (
			program.locationRequired &&
			!(schools.filter(school => school.id === parseInt(student.schoolId))[0].siteLocationId === parseInt(program.schoolId)) &&
			!(schools.filter(school => school.id === parseInt(student.schoolId))[0].id === parseInt(program.schoolId))
		)
			return false;
		return (
			grades &&
			parseInt(student.gradeId) >= parseInt(grades[gradeMinimum][0].id) &&
			parseInt(student.gradeId) <= parseInt(grades[gradeMaximum][0].id)
		);
	};

	const attemptEnrollment = () => {
		startScheduleValidation();
		setTimeout(() => {
			const errors = document.querySelectorAll('.error').length;
			if (errors === 0) {
				dispatch(addToStudentSchedule())
					.then(() => dispatch(addToSchedule()))
					.then(() => dispatch(refreshStudents()))
					.then(init(objects => dispatch(initialPayload(objects))));
			}
		}, 0);
	};

	const totalQualifiedStudents = eligibleStudents.filter(qualified).length + tentativeStudents.filter(qualified).length;

	return (
		<Main ref={ref}>
			<Grid.Two>
				<SubHeader>{program.title}</SubHeader>
				<div className='text-right'>
					<Link to='/scheduling'>
						<Button className='small' onClick={() => dispatch(clearSelection())}>
							Close
						</Button>
					</Link>
				</div>
			</Grid.Two>
			{!!schedules && schedules.length ? (
				<>
					{/* grades */}
					<ProgramInfo>{gradeStatement()}</ProgramInfo>
					{/* quick description */}
					<ProgramInfo>{program.generalTime}</ProgramInfo>
					{/* dates */}
					<ProgramInfo>
						{standardDate.format(new Date(program.startDate))} - {standardDate.format(new Date(program.endDate))}
					</ProgramInfo>
					{/* location */}
					<ProgramInfo>Location: {locationName()}</ProgramInfo>
					{/* description */}
					<ProgramDescription dangerouslySetInnerHTML={{ __html: program.description }}></ProgramDescription>
					<BlueLink onClick={handleRegRequirementsInfoShow}>Registration Requirements</BlueLink>
					{/* this was needed to isolate it from the child centering,  if I encounter this again, the component may need a css clearing div of its own */}
					<div>
						<Dialog show={showRegRequirementsInfo} onHide={handleRegRequirementsInfoClose}>
							<Dialog.Header closeButton>Registration Requirements</Dialog.Header>
							<Dialog.Body>
								Students must be {gradeStatement()}{' '}
								{!!program.locationRequired && (
									<>
										<br />
										Students must also be enrolled in a school, and have that school selected in their student information, that
										corresponds with the STAR site at {locationName()}.
									</>
								)}
							</Dialog.Body>
							<Dialog.Footer>
								<Button onClick={handleRegRequirementsInfoClose} className='small'>
									close
								</Button>
							</Dialog.Footer>
						</Dialog>
					</div>
					{/* eligible students */}
					{!!eligibleStudents.filter(qualified).length && (
						<div>
							<PurpleLink onClick={handleShowEligibilityInfoShow}>Eligible</PurpleLink> Students For Registration:{' '}
							<Dialog show={showEligibilityInfo} onHide={handleShowEligibilityInfoClose}>
								<Dialog.Header closeButton>Eligible Student Info</Dialog.Header>
								<Dialog.Body>
									These are students that already exist on your family account. You can easily select and request schedules for
									these students.
								</Dialog.Body>
								<Dialog.Footer>
									<Button onClick={handleShowEligibilityInfoClose} className='small'>
										close
									</Button>
								</Dialog.Footer>
							</Dialog>
							{eligibleStudents.filter(qualified).map((student, index) => (
								<div key={index}>
									{!!student && !!student.enrollmentBlock && student.enrollmentBlock.id === 0 ? (
										<div>
											<PurpleLink textOnly>{student.firstName}:</PurpleLink>
											<Bold> Select A Schedule</Bold>
											{!!schedules &&
												schedules.map((schedule, index) => (
													<WeekSelector
														key={index}
														schedule={schedule}
														student={student}
														validate={validateSchedules}
														stopValidate={clearScheduleValidation}
													/>
												))}
											<SelectedScheduleTotal studentId={student.id} monthly={program.monthly} />
										</div>
									) : (
										<div>
											{student.firstName}:<Highlighted>Ineligible for Enrollment - Expelled</Highlighted>
										</div>
									)}
								</div>
							))}
						</div>
					)}
					{/* tentative students */}
					{!!tentativeStudents.filter(qualified).length && (
						<div>
							<OrangeLink onClick={handleShowTentativeInfoShow}>Tentative</OrangeLink> Students For Registration:{' '}
							<Dialog show={showTentativeInfo} onHide={handleShowTentativeInfoClose}>
								<Dialog.Header closeButton>Tentative Student Info</Dialog.Header>
								<Dialog.Body>
									<p>These are students that are not added to a family account.</p>
									<p>
										They will need to be added to a complete family account. You will be able to select a schedule, but will be
										required to complete their information before the schedule is finalized.
									</p>
									<p>
										If you have added tentative students and do not have a family account, you will also have to create your
										family account before the schedule is finalized.
									</p>
								</Dialog.Body>
								<Dialog.Footer>
									<Button onClick={handleShowTentativeInfoClose} className='small'>
										close
									</Button>
								</Dialog.Footer>
							</Dialog>
							{tentativeStudents.filter(qualified).map((student, index) => (
								<div key={index}>
									<OrangeLink textOnly>{student.firstName}:</OrangeLink>
									<Bold> Select A Schedule</Bold>
									{!!schedules &&
										schedules.map((schedule, index) => <WeekSelector key={index} schedule={schedule} studentId={student.id} />)}
									<SelectedScheduleTotal studentId={student.id} monthly={program.monthly} />
								</div>
							))}
						</div>
					)}
					{/* alternate messaging area */}
					{/* logged out message should show for those who are not logged in AND no students */}
					{!user && (
						<Breathe as='p'>
							<OrangeLink textOnly>You are currently logged out,</OrangeLink>
							<span> Login To Proceed</span>
						</Breathe>
					)}
					{/* no students message if logged in without students */}
					{!!user && totalStudents === 0 && (
						<Breathe as='p'>
							<OrangeLink textOnly>Currently, you do not have any students</OrangeLink>
						</Breathe>
					)}

					<AutoGridColumn as={Breathe}>
						{/* add or edit student button should show for everyone except not logged in AND no students */}
						{(user || totalStudents > 0) && (
							<Tall as={Link} to='/students'>
								<Tall as={Button} className={`${totalQualifiedStudents && 'small'}`}>
									Edit Students
								</Tall>
							</Tall>
						)}
						{/* add schedules to reg button shows for anyone with students listed */}
						{totalQualifiedStudents > 0 && (
							<Button className='small' onClick={() => attemptEnrollment()}>
								Add Selections to Schedule
							</Button>
						)}
					</AutoGridColumn>
				</>
			) : (
				<ProgramDescription>This program has no currently running schedules.</ProgramDescription>
			)}
			{/* log in button should show for anyone not logged in */}
			<Breathe>
				{!user && (
					<AutoGridColumn as={Link} to='/'>
						<Button>Log In</Button>
					</AutoGridColumn>
				)}
			</Breathe>
		</Main>
	);
};
export default MoreInfo;
