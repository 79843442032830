import { apiCall } from "./API/apiCall";

export default function init(cb) {
    const fetch = apiCall(
        {
            init: true,
        },
        '/'
    )
    fetch.then(result => { cb(result); }).catch(result => { cb(result);});
}