import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { studentsSchedulesData } from '../../libs/API';
import { toast } from 'react-toastify';

const defaultInitialState = {
	currentSelection: {},
	intendedSchedule: {},
	addToSchedulePending: false,
	showSchedule: false,
	showScheduleBar: false,
	timer: 0,
	currentDelta: 0,
};

const initialLocalState = () => {
	const localstate = JSON.parse(localStorage.getItem('store2024'));
	if (localstate) return localstate.schedule ?? defaultInitialState;
	return defaultInitialState;
};

export const addToStudentSchedule = createAsyncThunk(
	'schedule/addToStudentScheduleStatus',
	async (final = false, { getState }) => {
		const schedules = getState().schedule.currentSelection;
		const response = await studentsSchedulesData.enroll(schedules, final);

		return response;
	},
	{
		condition: (_, { getState }) => {
			const state = getState().schedule;
			let total = 0;
			Object.entries(state.currentSelection).forEach(student => {
				Object.entries(student[1]).forEach(schedules => {
					total += schedules[1].monday + schedules[1].tuesday + schedules[1].wednesday + schedules[1].thursday + schedules[1].friday;
				});
			});
			if (!total) {
				toast.error('No schedule selected.');
				return false;
			}
		},
	}
);

export const resetEnrollmentTimer = createAsyncThunk(`schedule/resetEnrollmentTimerStatus`, async () => {
	const response = await studentsSchedulesData.resetTimer();
	return response;
});

export const clearSchedule = createAsyncThunk('schedule/clearScheduleStatus', async () => {
	const response = await studentsSchedulesData.clear();

	return response;
});

export const reloadSchedule = createAsyncThunk('schedule/reloadScheduleStatus', (studentsSchedules, { getState }) => {
	const state = getState();
	const schedules = Object.values(state.app.schedules).flat();
	let updatedSchedules = [];

	studentsSchedules.forEach(studentSchedule => {
		const schedule = schedules.find(schedule => schedule.id === studentSchedule.scheduleId);
		const fee = calcFee(
			schedule,
			studentSchedule.monday,
			studentSchedule.tuesday,
			studentSchedule.wednesday,
			studentSchedule.thursday,
			studentSchedule.friday,
			state.app.concurrentEnrollmentDiscounts[schedule.id],
			state.app.scheduleBilledTo,
			state.app.organizationalAccounts.find(account => account.id === state.app.primary.organizationalAccounts?.[0]?.organizationalAccountId),
			state.schedule.currentSelection && state.schedule.currentSelection[studentSchedule.studentId]
				? Object.entries(state.schedule.currentSelection[studentSchedule.studentId])
				: []
		);
		updatedSchedules.push({ ...studentSchedule, fee: fee });
	});
	return updatedSchedules;
});

export const removeSchedule = createAsyncThunk('schedule/removeScheduleStatus', async ({ studentId, scheduleId }) => {
	const response = await studentsSchedulesData.remove(studentId, scheduleId);

	return response;
});

const calcFee = (schedule, monday, tuesday, wednesday, thursday, friday, discount, schedulesBilledTo, organizationalAccount, currentSelections) => {
	console.log(schedulesBilledTo);
	schedulesBilledTo.forEach(scheduleBilledTo => {
		console.log(scheduleBilledTo.scheduleId, schedule.id);
		console.log(organizationalAccount);
		console.log(scheduleBilledTo.userId, organizationalAccount.userId);
		if (scheduleBilledTo.scheduleId === schedule.id && organizationalAccount && scheduleBilledTo.userId === organizationalAccount.userId) {
			return 0;
		}
	});
	let useDiscount = false;
	if (discount && discount.length) {
		currentSelections.forEach(scheduleItem => {
			if (discount.some(disc => parseInt(scheduleItem[0]) === parseInt(disc.concurrentId))) {
				if (
					scheduleItem[1].monday + scheduleItem[1].tuesday + scheduleItem[1].wednesday + scheduleItem[1].thursday + scheduleItem[1].friday >
					1
				) {
					useDiscount = true;
				}
			}
		});
	}
	switch (monday + tuesday + wednesday + thursday + friday) {
		case 1:
			return useDiscount ? Number(discount[0].oneDay) : Number(schedule.oneDay) + Number(schedule.fee);
		case 2:
			return useDiscount ? Number(discount[0].twoDay) : Number(schedule.twoDay) + Number(schedule.fee);
		case 3:
			return useDiscount ? Number(discount[0].threeDay) : Number(schedule.threeDay) + Number(schedule.fee);
		case 4:
			return useDiscount ? Number(discount[0].fourDay) : Number(schedule.fourDay) + Number(schedule.fee);
		case 5:
			return useDiscount ? Number(discount[0].fiveDay) : Number(schedule.fiveDay) + Number(schedule.fee);
		default:
			return undefined;
	}
};

export const scheduleSlice = createSlice({
	name: 'schedule',
	initialState: initialLocalState(),
	reducers: {
		addSelection: (state, action) => {
			state.currentSelection = {
				...state.currentSelection,
				[action.payload.studentId]: {
					...state.currentSelection[action.payload.studentId],
					[action.payload.scheduleId]: {
						twoDayMinimum: action.payload.twoDayMinimum,
						monday: action.payload.monday,
						tuesday: action.payload.tuesday,
						wednesday: action.payload.wednesday,
						thursday: action.payload.thursday,
						friday: action.payload.friday,
						fee: action.payload.fee,
					},
				},
			};
		},
		clearSelection: state => {
			state.currentSelection = {};
		},
		showSchedule: state => {
			state.showSchedule = true;
		},
		closeSchedule: state => {
			state.showSchedule = false;
			state.showScheduleBar = true;
		},
		addToSchedule: state => {
			Object.entries(state.intendedSchedule).forEach(student => {
				Object.entries(student[1]).forEach(schedule => {
					if (!!(schedule[1].monday + schedule[1].tuesday + schedule[1].wednesday + schedule[1].thursday + schedule[1].friday)) {
						state.intendedSchedule = {
							...state.intendedSchedule,
							[student[0]]: {
								...state.intendedSchedule[student[0]],
								[schedule[0]]: {
									monday: schedule[1].monday,
									tuesday: schedule[1].tuesday,
									wednesday: schedule[1].wednesday,
									thursday: schedule[1].thursday,
									friday: schedule[1].friday,
									fee: schedule[1].fee,
								},
							},
						};
					}
				});
			});
		},
		hideSchedule: state => {
			state.showSchedule = false;
		},
		showScheduleBar: state => {
			state.timer = state.currentDelta;
			state.showScheduleBar = true;
		},
		hideScheduleBar: state => {
			state.showScheduleBar = false;
		},
		logout: () => defaultInitialState,
		setTimer: (state, action) => {
			state.timer = action.payload;
			if (action.payload > 0) state.showScheduleBar = true;
		},
		setDelta: (state, action) => {
			state.currentDelta = action.payload;
		},
		syncTimer: state => {
			state.timer = state.currentDelta;
		},
		showBackgroundLoader: (state, action) => {
			action.payload ? (state.addToSchedulePending = true) : (state.addToSchedulePending = false);
		},
	},
	extraReducers: {
		[addToStudentSchedule.pending]: state => {
			state.addToSchedulePending = true;
			state.timer = state.timer + 1;
		},
		[addToStudentSchedule.fulfilled]: (state, action) => {
			state.addToSchedulePending = false;
			action.payload.messages.forEach(message => {
				// right now we are ony handling toast types
				if (message.type === 'toast') {
					message.error ? toast.error(message.text) : toast(message.text);
				}
			});
			if (action.payload.array.length) {
				action.payload.array.forEach(schedule => {
					state.intendedSchedule = {
						...state.intendedSchedule,
						[schedule.studentId]: {
							...state.intendedSchedule[schedule.studentId],
							[schedule.scheduleId]: {
								monday: schedule.monday,
								tuesday: schedule.tuesday,
								wednesday: schedule.wednesday,
								thursday: schedule.thursday,
								friday: schedule.friday,
								fee: state.currentSelection[schedule.studentId][schedule.scheduleId].fee,
							},
						},
					};
				});
				state.showSchedule = true;

				state.timer = action.payload.time * 60000;
			} else {
				toast.error('No schedule to update.');
			}
		},
		[resetEnrollmentTimer.fulfilled]: (state, action) => {
			state.timer = action.payload.time * 60000;
		},
		[clearSchedule.fulfilled]: (_, action) => {
			action.payload.messages.forEach(message => {
				// right now we are ony handling toast types
				if (message.type === 'toast') {
					message.error ? toast.error(message.text) : toast(message.text);
				}
			});
			return defaultInitialState;
		},
		[reloadSchedule.fulfilled]: (state, action) => {
			action.payload.forEach(schedule => {
				state.intendedSchedule = {
					...state.intendedSchedule,
					[schedule.studentId]: {
						...state.intendedSchedule[schedule.studentId],
						[schedule.scheduleId]: {
							monday: schedule.monday,
							tuesday: schedule.tuesday,
							wednesday: schedule.wednesday,
							thursday: schedule.thursday,
							friday: schedule.friday,
							fee: schedule.fee,
						},
					},
				};
			});
		},
		[removeSchedule.pending]: state => {
			state.addToSchedulePending = true;
			state.timer = state.timer + 1;
		},
		[removeSchedule.fulfilled]: (state, action) => {
			action.payload.messages.forEach(message => {
				// right now we are ony handling toast types
				if (message.type === 'toast') {
					message.error ? toast.error(message.text) : toast(message.text);
				}
			});
			if (action.payload.array.length) {
				state.addToSchedulePending = false;
				state.intendedSchedule = {};
				action.payload.array.forEach(schedule => {
					state.intendedSchedule = {
						...state.intendedSchedule,
						[schedule.studentId]: {
							...state.intendedSchedule[schedule.studentId],
							[schedule.scheduleId]: {
								monday: schedule.monday,
								tuesday: schedule.tuesday,
								wednesday: schedule.wednesday,
								thursday: schedule.thursday,
								friday: schedule.friday,
								fee: schedule.fee,
							},
						},
					};
				});
				state.showSchedule = true;
				state.timer = action.payload.time * 60000;
			} else {
				toast.error('All schedules removed.');
				return defaultInitialState;
			}
		},
	},
});

export const {
	addSelection,
	clearSelection,
	showSchedule,
	closeSchedule,
	addToSchedule,
	hideSchedule,
	showScheduleBar,
	hideScheduleBar,
	logout,
	setTimer,
	setDelta,
	syncTimer,
	showBackgroundLoader,
} = scheduleSlice.actions;

export const getCurrentSelection = state => state.schedule.currentSelection;
export const getCurrentStudentSelection = (state, studentId) => {
	return state.schedule.currentSelection && state.schedule.currentSelection[studentId]
		? Object.entries(state.schedule.currentSelection[studentId])
		: [];
};
export const getIntendedSchedule = state => state.schedule.intendedSchedule;
export const getSelectionFee = (state, studentId) => {
	return state.schedule.currentSelection && state.schedule.currentSelection[studentId]
		? Object.entries(state.schedule.currentSelection[studentId]).reduce((prev, next) => prev + (next[1].fee || 0), 0)
		: 0;
};
export const getShowSchedule = state => state.schedule.showSchedule;
export const getShowScheduleBar = state => state.schedule.showScheduleBar;
export const getAddToSchedulePendingState = state => state.schedule.addToSchedulePending;
export const getTimerValue = state => state.schedule.timer;

export default scheduleSlice.reducer;
