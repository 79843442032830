import styled from 'styled-components';

export const Card = styled.div`
	padding: 0.5rem 1rem;
	background: ${({ theme }) => theme.background};
`;

export const Bolder = styled.div`
	font-weight: bolder;
`;

export const StyledLabel = styled.label`
	color: ${({ theme }) => theme.darkGray};
	background: ${({ theme }) => theme.secondaryColorHover};
	display: inline-block;
    padding: 0.33333rem 0.5rem;
	margin-left: 1rem;
    border-radius: 0;
    font-size: 0.8rem;
    line-height: 1;
    white-space: nowrap;
    cursor: default;
`