import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import Button from '../button/Button';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { standardDate } from '../../libs/app_lib';
import { getSchools, getGrades, updateTitle } from '../../dataStore/reducers/appSlice';
import { addStudent, getSelectedStudent } from '../../dataStore/reducers/studentSlice';

import { Input, Small, StyledErrorMessage, StyledForm, StyledLabel } from './addStudent.styled';
import { studentData } from '../../libs/API';
import { toast } from 'react-toastify';

function AddStudent() {
	const dispatch = useDispatch();
	let navigate = useNavigate();
	const location = useLocation();
	const schools = useSelector(getSchools);
	const grades = useSelector(getGrades);
	const selectedStudent = useSelector(getSelectedStudent);

	useEffect(() => {
		if (location.pathname === '/addStudent') {
			if (selectedStudent) {
				dispatch(updateTitle('Edit Student'));
			} else {
				dispatch(updateTitle('New Student'));
			}
		}
	});
	return (
		<Formik
			initialValues={{
				schoolId: selectedStudent?.schoolId ?? '',
				firstName: selectedStudent?.firstName ?? '',
				lastName: selectedStudent?.lastName ?? '',
				gradeId: selectedStudent?.gradeId ?? '',
				birthdate: (selectedStudent ? new Date(selectedStudent.birthdate) : new Date()).toISOString().split('T')[0],
			}}
			validationSchema={Yup.object({
				schoolId: Yup.number().required("Please select your student's school."),
				firstName: Yup.string().required('Your student must have a first name.'),
				lastName: Yup.string().required('Your student must have a last name.'),
				gradeId: Yup.number().required('Please select a grade.'),
				birthdate: Yup.date().required("You must enter your student's birthdate."),
			})}
			onSubmit={(values, { setSubmitting }) => {
				console.info(values);
				const sendValues = {};
				sendValues.id = selectedStudent?.id ?? -Math.floor(Math.random() * 1000);
				sendValues.schoolName = schools.find(school => school.id === parseInt(values.schoolId)).shortName;
				sendValues.schoolId = values.schoolId;
				sendValues.shortGradeName = grades.find(grade => parseInt(grade.id) === parseInt(values.gradeId)).shortGrade;
				sendValues.gradeId = values.gradeId;
				sendValues.birthdate = standardDate.format(values.birthdate);
				console.info(sendValues);
				values = studentData
					.update(sendValues)
					.then(({ student, message }) => {
						dispatch(addStudent(student));
						toast(message);
					})
					.catch(errorMsg => toast.error(errorMsg));

				navigate('/students');
				setSubmitting(false);
			}}>
			{({ errors, touched, values, handleChange, handleBlur }) => (
				<StyledForm>
					{/* school */}
					<StyledLabel htmlFor='schoolId'>Choose Location</StyledLabel>
					<Input id='schoolId' name='schoolId' as='select' defaultValue={values.schoolId} onChange={handleChange} onBlur={handleBlur}>
						<option value='' hidden>
							Choose Location
						</option>
						{schools.map((school, index) => (
							<option value={school.id} key={index}>
								{school.shortName}
							</option>
						))}
					</Input>
					<StyledErrorMessage name='schoolId' component='div' />
					{/* firstName */}
					<StyledLabel htmlFor='firstName'>Student's First Name</StyledLabel>
					<Input placeholder="Student's First Name" id='firstName' name='firstName' type='text' />
					<StyledErrorMessage name='firstName' component='div' />
					{/* lastName */}
					<StyledLabel htmlFor='lastName'>Student's Last Name</StyledLabel>
					<Input placeholder="Student's Last Name" id='lastName' name='lastName' type='text' />
					<StyledErrorMessage name='lastName' component='div' />
					{/* grade */}
					<StyledLabel htmlFor='gradeId'>Student's Grade</StyledLabel>
					<Input id='gradeId' name='gradeId' as='select' defaultValue={values.gradeId} onChange={handleChange} onBlur={handleBlur}>
						<option value='' hidden>
							Student's Grade
						</option>
						{grades.map((grade, index) => (
							<option value={grade.id} key={index}>
								{grade.grade}
							</option>
						))}
					</Input>
					<StyledErrorMessage name='gradeId' component='div' />
					{/* birthdate */}
					<StyledLabel htmlFor='birthdate'>Student's Birth Date</StyledLabel>
					<Input placeholder="Student's Birth Date" id='birthdate' name='birthdate' type='date' />
					<StyledErrorMessage name='birthdate' component='div' />
					<Small>All Set?</Small>
					<div>
						<Button type='submit' className='expanded'>
							Save Student
						</Button>
					</div>
				</StyledForm>
			)}
		</Formik>
	);
}

export default AddStudent;
