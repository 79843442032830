import { animated } from '@react-spring/web';
import styled from 'styled-components';
import { centerChildren } from '../../styles/util';

export const Main = styled(animated.div)`
	${centerChildren}
	background-color: ${({ theme }) => theme.preschoolColor};
`;

export const Title = styled.div`
	color: ${({ theme }) => theme.white};
	font-size: 1.125rem;
	font-family: ${({ theme }) => theme.fntSerif};
`;

export const Timer = styled.span`
	color: ${({ theme }) => theme.preschoolColor};
	padding: 0 0.25rem;
	font-family: Roboto;
	background-color: ${({ theme }) => theme.white};
`;
